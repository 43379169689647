<template>
    <div class="contact" id="contact">

        <div class="wdt-x10 content-white flex-row-center start-row-center cont-form-contact box-shadow">
            
            <div class="wdt-x8 full-height form-contact content-grey-d flex-row-center start-row-center ">

                <div class="wdt-x10 full-height form flex-column-center center-column-start">

                    <span class="mg-bottom-x2 text-medium text-primary text-title">Contáctanos</span>

                    <FormContact></FormContact>

                </div>

                <div class="wdt-x4 content-red hgh-x6 info-contact flex-column-center center-column-start">
                    
                    <span class="text-medium text-title text-white mg-bottom-x2 title-info-contact">Información</span>

                    <div 
                        class="flex-column-center start-column-start mg-bottom-x2 section-info-contact"
                        v-for="(item, i) in info"
                        :key="'info_' + i"
                    >
                        <div
                            class="flex-row-center start-row-center mg-bottom-x05"
                            v-for="(item, j) in item"
                            :key="'info_' + i + j"
                        >
                            <font-awesome-icon 
                                :icon="['fa', item.icon]" 
                                class="icon icon-white icon-medium social mg-right-x1"
                            />
                            <span class="no-margin text-white text-light text-subtitle-section">{{ item.value }}</span>
                        </div>
                    </div>

                </div>
            </div>

            <div class="wdt-x2 full-height content-grey-dd flex-column-center end-column-center pd-top-x2 pd-bottom-x2 column-contact">

                <div class="flex-row-center start-row-center cont-social wdt-x10 pd-left-x3 pd-right-x3">
                    <font-awesome-icon 
                        v-for="(item, index) in socials"
                        :key="'social_' + index"
                        :icon="['fab', item.icon]" 
                        class="icon icon-primary icon-large social"
                        @click="openSocial(item.url)"
                    />
                </div>
            </div>


        </div>
    </div>
</template>

<script>

import FormContact from "@/components/landing/FormContact.vue";

    export default {
        data:() => {
            return {
                socials: [
                    {
                        icon: "facebook",
                        url: "https://www.facebook.com/dr4400"
                    },
                    {
                        icon: "twitter",
                        url: "https://twitter.com/rotaryecuador"
                    },
                    {
                        icon: "instagram",
                        url: "https://www.instagram.com/rotary.ecuador/"
                    }
                ],

                info: [
                        [
                            {
                                icon: "user",
                                value: "Bill Stumbaugh"
                            },
                            {
                                icon: "phone-alt",
                                value: "+593-969752207"
                            },
                            {
                                icon: "envelope",
                                value: "feriadeproyectosecuador2024@gmail.com"
                            }
                        ],
                ]
            };
        },

        methods: {
            openSocial(url) {
                window.open(url, '_blank');
            }
        },

        components: {
            FormContact
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/css/App.scss';

    .contact {
        padding: 6vw;

        @include media("<phone") {
            padding: 0;
        }

        @include media(">phone", "<tablet") {
            padding: 8vw 4vw;
        }
        
        @include media(">=768px", "<=1024px") {
            
        }

        @include media(">tablet", "<desktop") {

        }

        .cont-form-contact {
            height: auto;
            position: relative;
            border-radius: .8em;

            @include media("<phone") {
                display: flex !important;
                flex-flow: column !important;
                justify-content: center !important;
                align-items: center !important;
            }

            @include media(">phone", "<tablet") {
                display: flex !important;
                flex-flow: column !important;
                justify-content: center !important;
                align-items: center !important;
            }
            
            @include media(">=768px", "<=1024px") {
                display: flex !important;
                flex-flow: column !important;
                justify-content: center !important;
                align-items: center !important;
            }
            

            .form-contact {

                @include media("<phone") {
                    width: 100% !important;
                    display: flex !important;
                    flex-flow: column !important;
                    justify-content: center !important;
                    align-items: center !important;
                    padding: 8vw 0 0 0;
                }

                @include media(">phone", "<tablet") {
                    width: 100% !important;
                    display: flex !important;
                    flex-flow: column !important;
                    justify-content: center !important;
                    align-items: center !important;
                    padding: 8vw 0 0 0;
                }
                
                @include media(">=768px", "<=1024px") {
                    width: 100% !important;
                    display: flex !important;
                    flex-flow: column !important;
                    justify-content: center !important;
                    align-items: center !important;
                    padding: 8vw 0 0 0;
                }

                @include media(">tablet", "<desktop") {
                    
                }

                .form {
                    padding: 4vw 6vw;

                    @include media("<phone") {
                        padding: 2vw 4vw;
                        margin-bottom: 4vw;
                    }

                    @include media(">phone", "<tablet") {
                        padding: 2vw 4vw;
                        margin-bottom: 4vw;
                    }
                    
                    @include media(">=768px", "<=1024px") {
                        padding: 2vw 4vw;
                        margin-bottom: 4vw;
                    }

                    @include media(">tablet", "<desktop") {

                    }

                }

                .info-contact {
                    position: absolute;
                    right: 0;
                    padding: 2vw;
                    border-radius: .4vw;

                    @include media("<phone") {
                        position: relative;
                        width: 100%;
                        padding: 4vw;
                    }

                    @include media(">phone", "<tablet") {
                        position: relative;
                        width: 100%;
                        padding: 4vw;
                    }
                    
                    @include media(">=768px", "<=1024px") {
                        position: relative;
                        width: 100%;
                        padding: 4vw;
                    }

                    @include media(">tablet", "<desktop") {

                    }

                    .title-info-contact {
                        @include media("<phone") {
                            margin-bottom: 4vw;
                        }

                        @include media(">phone", "<tablet") {
                            margin-bottom: 4vw;
                        }
                        
                        @include media(">=768px", "<=1024px") {
                            margin-bottom: 4vw;
                        }
                    }

                    .section-info-contact {
                        @include media("<phone") {
                            margin-bottom: 4vw;
                        }
                    }
                }
            }

            .column-contact {

                @include media("<phone") {
                    display: none;
                }

                @include media(">phone", "<tablet") {
                    display: none;
                }
                        
                @include media(">=768px", "<=1024px") {
                    display: none;
                }
                

                .cont-social {
                    justify-content: space-between;
    
                    .social {
                        height: 100%;    
                    }
                }
            }
            

        }
    }
</style>