<template>
    <div 
        class="flex-row-center start-row-center full-width"
        :class="active ? 'show loader' : 'hide'"
    >
        <img src="@/assets/images/loader.svg" />
        <span class="no-margin text-light text-primary full-width">{{ message }}</span>
    </div>
</template>

<script>

export default {
    props: {
        message: {
            type: String,
            default:""
        },
        active: {
            type: Boolean,
            default:false
        }
    }
};
    
</script>



<style lang="scss" scoped>
	@import '@/assets/css/App.scss';
</style>
