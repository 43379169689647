export const inListData = (list) => {

    let format_data = [];

    list.forEach(item => {
        format_data.push({
            id  : item.id,
            data: item.data()
        });
    });

    format_data.sort( (a, b) => {
        if (a.data.date_register < b.data.date_register) {
          return 1;
        }
        if (a.data.date_register > b.data.date_register) {
          return -1;
        }
        return 0;
    });

    return format_data;
}

export const inListDataRelated = (list, id_project) => {

    let format_data = [];

    list.forEach(item => {

        if (item.id !== id_project) {
            format_data.push({
                id  : item.id,
                data: item.data()
            });
        }
    });

    format_data.sort( (a, b) => {
        if (a.data.date_register < b.data.date_register) {
          return 1;
        }
        if (a.data.date_register > b.data.date_register) {
          return -1;
        }
        return 0;
    });

    return format_data;
}

export const list_focuses_area = () => {

    let focus_area = [
        {
            code: "peace",
            name: "Paz y prevención de conflictos",
            description: "Rotary fomenta el diálogo para promover la comprensión internacional entre los pueblos y culturas. Ayudamos en la formación de líderes adultos y jóvenes para que sean actores en la prevención y mediación de conflictos y brinden apoyo a refugiados que huyen de situaciones de peligro.",
            image: "icon_paz-y-prevencion-de-conflictos.png",
            tag: "icon_paz-y-prevencion-de-conflictos",
            total: 0,
            color: "#0067C8"
        },
        {
            code: "diseases",
            name: "Prevención y tratamiento de enfermedades",
            description: "Sensibilizamos y equipamos a las comunidades para contener la propagación de enfermedades mortales como la polio, el VIH/SIDA y el paludismo. Nuestra meta es mejorar y ampliar el acceso a la atención de la salud, de bajo costo o gratuita, en las regiones en desarrollo.",
            image: "icon_prevencion-y-tratamiento-de-enfermedades.png",
            tag: "icon_prevencion-y-tratamiento-de-enfermedades",
            total: 0,
            color: "#e02927"
        },        
        {
            code: "water",
            name: "Agua, saneamiento e higiene",
            description: "Apoyamos iniciativas locales mediante las cuales más personas tienen acceso al agua salubre, saneamiento e higiene. Nuestro propósito no es simplemente construir pozos sino compartir nuestras experiencias con los líderes y docentes de la comunidad para que nuestros proyectos sean sostenibles.",
            image: "icon_agua-y-saneamiento.png",
            tag: "icon_agua-y-saneamiento",
            total: 0,
            color: "#00a2e0"
        },        
        {
            code: "health",
            name: "Salud materno-infantil",
            description: "Anualmente alrededor de seis millones de niños menores de cinco años fallecen víctimas de la desnutrición, falta de atención de la salud y saneamiento insalubre. Al ampliar el acceso a mejores servicios de salud, madres e hijos viven sanos y fuertes.",
            image: "icon_salud-materno-infantil.png",
            tag: "icon_salud-materno-infantil",
            total: 0,
            color: "#901f93"
        },        
        {
            code: "education",
            name: "Alfabetización y educación básica",
            description: "El analfabetismo afecta a más de 775 millones de personas mayores de 15 años. Nuestra meta es alentar a las comunidades para que apoyen programas de alfabetización y educación básica, reduzcan la disparidad de género en la educación y aumenten la tase de alfabetismo entre los adultos.",
            image: "icon_educacion-basica-y-alfabetizacion.png",
            tag: "icon_educacion-basica-y-alfabetizacion",
            total: 0,
            color: "#ff7600"
        },        
        {
            code: "economy",
            name: "Desarrollo económico de la comunidad",
            description: "Nuestros proyectos de servicio están diseñados para fomentar el desarrollo integral y económico de las comunidades y crear oportunidades de trabajo productivo y remunerado para personas de todas las edades. Trabajamos también con emprendedores y líderes locales y en particular ayudamos a la mujer en comunidades empobrecidas.",
            image: "icon_desarrollo-economico-de-la-comunidad.png",
            tag: "icon_desarrollo-economico-de-la-comunidad",
            total: 0,
            color: "#00adbb"
        },        
        {
            code: "environment",
            name: "Medioambiente",
            description: "Los socios de Rotary abordan los temas ambientales como siempre lo hacen: proponiendo proyectos, empleando sus conexiones para cambiar políticas y planificando para el futuro.",
            image: "icon_medio-ambiente.png",
            tag: "icon_medio-ambiente",
            total: 0,
            color: "#009739"
        }
    ];

    return focus_area;

}


export const format_images_list = (list) => {

    let images = [];

    list.forEach(item => {
        images.push({
            name: item.name,
            path: item.path
        })
    });

    return images;

}