<template>
    <div class="laingments wdt-x10 flex-row-center center-row-center" id="laingments">
        <img src="@/assets/images/trama-color-fair.png" alt="" class="trama"/>


        <div class="wdt-x5 full-height flex-column-center center-column-start presentation">
            <span class="text-primary text-title text-medium">
                {{ title }}
            </span>

            <span class="text-light text-subtitle-section mg-bottom-x2">
                {{ subtitle_a }}
            </span>

            <div class="wdt-x10 flex-row-center start-row-center cont-button-download">
                <button 
                    class="content-red text-white text-medium text-button first"
                    @click="openURL(welcome_pack)"
                >
                    {{ button_a }}
                </button>
            </div> 

        </div>

        <div class="wdt-x5 full-height video ">
            <LazyVimeo  
                ref="vimeoLazyVideo"
                :src="videoVimeo"
                max-width="100%"
                max-height="100%"
                aspect-ratio="1:1"
                thumbnail-quality="standard"
                customThumbnail="img/portada_video.png"
            />
            
        </div>

    </div>
</template>

<script>
import { LazyVimeo } from "vue-lazytube";

export default {
    name: 'Laingments',
    data() {
        return {
            title: "Bienvenidos Agentes de Cambio",
            subtitle_a: "Conoce el Ecuador y descubre la magia de servir para cambiar vidas. Sé parte de nuestra XVIII Feria de Proyectos Rotary Ecuador",
            subtitle_b: "Sé parte de nuestra XVIII Feria de Proyectos Rotary Ecuador 2024",
            button_a: "DESCARGAR FOLLETO",
            button_b: "MATERIAL ADICIONAL",
            videoVimeo: "https://vimeo.com/948382315",
            welcome_pack: "https://firebasestorage.googleapis.com/v0/b/projects-fair-rotary.appspot.com/o/sources%2FBrochure-Feria-2024.pdf?alt=media&token=b6bac806-1093-4b02-9323-3252d29dfd3b",
            aditional_material: "https://drive.google.com/drive/folders/1zBv_xOnqqrVAmJrVNqrHMv-BTe4Ctc_2?usp=sharing"
        };
    },

    mounted() {
        
    },

    methods: {
        openURL(url) {
            window.open(url, "blank");
        }
    },

    components: {
        LazyVimeo
    }
};
</script>

<style lang="scss" scoped>
    @import '@/assets/css/App.scss';


    .rXUqC {
        background-color: #fff !important;
        height: 100%;
    }

    $space-content: 12vw;

    .laingments {
        position: relative;
        padding: 12vw $space-content 4vw $space-content;

        @include media("<phone") {
            display: flex !important;
            flex-flow: column !important;
            justify-content: center !important;
            align-items: center !important; 
            padding: 12vw 8vw 8vw 8vw;
        }

        @include media(">phone", "<tablet") {
            display: flex !important;
            flex-flow: column !important;
            justify-content: center !important;
            align-items: center !important; 
            padding: 12vw 8vw 8vw 8vw;
        }

        @include media(">=768px", "<=1024px") {
            display: flex !important;
            flex-flow: column !important;
            justify-content: center !important;
            align-items: center !important; 
            padding: 10vw 8vw 8vw 8vw;
        }

        @include media(">tablet", "<desktop") {

        }

        .cont-button-download{ 
            justify-content: space-between;

            @include media("<phone") {
                display: flex !important;
                flex-flow: column !important;
                justify-content: center !important;
                align-items: center !important; 
                padding: 1em 0 2em 0;
            }

            @include media(">phone", "<tablet") {
                display: flex !important;
                flex-flow: column !important;
                justify-content: center !important;
                align-items: center !important; 
                padding: 1em 0 2em 0;
            }

            @include media(">=768px", "<=1024px") {
                padding: 0em 0 2em 0;
            }


            .text-button {
                @include media("<phone") {
                    width: 100%;
                    margin: 0;
                }

                @include media(">phone", "<tablet") {
                    width: 100%;
                    margin: 0;
                }
            }

            .text-button.first {
                @include media("<phone") {
                    margin-bottom: 1.5em;
                }

                @include media(">phone", "<tablet") {
                    margin-bottom: 1.5em;
                }
            }
        }

        .trama {
            height: 100%;
            position: absolute;
            left: -2vw;
            top: 0;
            bottom: 0;
            margin: auto 0;

            @include media("<phone") {
                display: none !important;
            }

            @include media(">phone", "<tablet") {
                display: none !important;
            }

            @include media(">=768px", "<=1024px") {
                display: none !important;
            }
        }

        .presentation {
            padding: 3vw;

            @include media("<phone") {
                width: 100%;
                padding: 0;
            }

            @include media(">phone", "<tablet") {
                width: 100%;
                padding: 0;
            }

            @include media(">=768px", "<=1024px") {
                width: 100%;
                padding: 0;
            }
        }

        .video {
            padding: 3vw;
             @include media("<phone") {
                width: 100%;
                height: 60% !important;
                padding: 0;
            }

            @include media(">phone", "<tablet") {
                width: 100%;
                padding: 0;
            }

            @include media(">=768px", "<=1024px") {
                width: 100%;
                padding: 0;
            }
        }
    }

</style>