<template>
    <div class="description wdt-x6 flex-column-center start-column-center pd-right-x1">

        <div class="wdt-x10 flex-column-center start-column-start mg-bottom-x05">
            <span class="text-medium text-subtitle">
                {{ project.title }}
            </span>
        </div>

        <div class="wdt-x10 flex-row-center center-row-start section_slider mg-top-x05 change-to-column">

            <div class="wdt-x5 content-grey-d cont-images border-focus-environment flex-row-center center-row-center">
                <slither-slider :options="options" ref="slider" class="full-size" >
                    <img 
                        :src="slide"
                        :key="'slide_'+i"
                        v-for="(slide, i) in project.images"
                    />
                </slither-slider>
            </div>

            <div class="wdt-x5 cont-description pd-left-x2">

                <span class="wdt-x10 text-medium text-grey text-subtitle-section">
                    Monto estimado
                </span>

				<span class="wdt-x10 text-medium text-subtitle-section mg-bottom-x2">
                    {{ '$ ' + project.estimated_amount }}
                </span>

                <span class="wdt-x10 text-medium text-grey resp-title-section text-subtitle-section">
                    Enlaces de interés
                </span>

                <span 
                    class="text-medium text-grey-d text-subtitle-section"
                    :class="'color-focus-' + project.focus_area.code"
                    v-if="project.focus_link == '' && project.link == '' && project.document == ''"
                >
                    No hay enlaces de interés
                </span>

                <button 
                    class="content-secondary text-white text-medium wdt-x10 mg-bottom-x1 text-button "
                    v-if="project.focus_link !== ''"
                    @click="openURL(project.focus_link)"
                >
                    Información adicional
                </button>

                <button 
                    class="content-green text-white text-medium wdt-x10 mg-bottom-x1 text-button"
                    v-if="project.link !== ''"
                    @click="openURL(project.link)"
                >
                    Repositorio del proyecto
                </button>

                <button 
                    class="content-red text-white text-medium wdt-x10 text-button"
                    v-if="project.document !== ''"
                    @click="openURL(project.document)"
                >
                    Descargar resumen
                </button>



                <!-- <span class="text-light text-subtitle-section">
                    {{ project.description}}
                </span> -->
                
            </div>

            <img 
                :src="returnIcon(project.focus_area.code)" 
                :alt="project.focus_area.name" 
                class="icon-focus"
            />
        </div>
    </div>
</template>

<script>

    export default {
        data: () => {
            return {
                options: {
                    controls: false,
                    autoplay: true,
                    dots: false,
                    fullscreen: true,
                    touch: true,
                    transition: "slide",
                    animationDuration: 3000,
                    animationEasing: "easeOutQuint",
                    slidePosition: "center"
                },
                height_slider: 0,
                focuses: [],

            };
        },
        
        props: {
            project: {
                type: Object,
                default: () => {
                    return { };
                }
            }
        },

        methods: {
            returnIcon(code) {
                var images = require.context('@/assets/images/', false, /\.png$/);
                return images('./icon-' + code + ".png");
            },

            openURL(url) {
                window.open(url, "_blank");
            }
        }
        
    }
</script>


<style lang="scss" scoped>
    @import '@/assets/css/App.scss';

    .description {

        @include media("<phone") {
            width: 100% !important;
        }

        @include media(">phone", "<tablet") {
            width: 100% !important;
        }

        @include media(">=768px", "<=1024px") {
            width: 100% !important;
        }


        .section_slider{
            position: relative;
        }

        .cont-images {
            height: 18vw;
            position: relative;
            overflow: hidden;
            border-radius: .4em;


            @include media("<phone") {
                width: 100% !important;
                height: 90vw;
                margin-bottom: 6vw;
            }

            @include media(">phone", "<tablet") {
                width: 100% !important;
                height: 90vw;
                margin-bottom: 6vw;
            }

            @include media(">=768px", "<=1024px") {
                width: 55% !important;
                margin: 0 auto;
                height: 50vw;
                margin-bottom: 6vw;
            }

            img {
                max-height: 100%;
                max-width: 100%;
                height: auto;
                width: auto;
                margin: 0 auto;
                display: block;
                object-fit: cover;
                transform: scale(1.1);
            }


        }

        .cont-description {
            @include media("<phone") {
                width: 100% !important;
            }

            @include media(">phone", "<tablet") {
                width: 100% !important;
            }
            @include media(">=768px", "<=1024px") {
                width: 100% !important;
            }
        }

        .icon-focus {
            position: absolute;
            left: 30%;                
            bottom: -4vw;
            z-index: 99;

            @include media("<phone") {
                display: none !important;
            }

            @include media(">phone", "<tablet") {
                display: none !important;
            }

            @include media(">=768px", "<=1024px") {
                display: none !important;
            }
        }
    }
</style>