<template>
    <div class="portada" id="portada">

        <div class="flex-column-center start-column-end wdt-x10 full-height">
            <img src="@/assets/images/logo-white.png" alt="" class="logo-white"/>

            <div class="hgh-x05"></div>

            <div class="full-width flex-row-center start-row-center cont-info full-height">
                <div class="wdt-x6 content-purple hgh-x6 info-invite flex-row-center center-row-center">
                    <div class="wdt-x7 pd-left-x0 pd-right-x3 full-height flex-column-center center-column-start text-left">
                        
                        <span class="text-light text-title-section text-white mg-bottom-x4">
                            {{ text.invite }}
                        </span>

                        <span class="text-light text-title-section text-white no-margin">
                            ¡Te esperamos!
                        </span>

                    </div>

                    <div class="wdt-x3 pd-left-x3 full-height flex-column-center center-column-start text-right">
                        <span class="text-light text-subtitle text-white no-margin">
                            11-12
                        </span>
                        <span class="no-margin text-bold text-white text-title">
                            ABR
                        </span>
                        <span class="no-margin text-bold text-white text-title">
                            2024
                        </span>
                    </div>
                </div>
                <div class="wdt-x9 content-white full-height cont-video">
                    <LazyVimeo  
                        ref="vimeoLazyVideo"
                        :src="videoVimeo"
                        max-width="100%"
                        aspect-ratio="16:9"
                        thumbnail-quality="standard"
                        customThumbnail="img/portada_video.png"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { LazyVimeo } from "vue-lazytube";

export default {
    data: () => {
        return {

            countries: [
                {
                    code: "en|es",
                    title: "Spanish",
                },
                {
                    code: "en|en",
                    title: "English",
                },
            ],

            text: {
                invite: "Únete al desafío de Servir para Cambiar Vidas y alcanzar el respeto a la diversidad, la equidad  e inclusión",
            },
            videoVimeo: "https://vimeo.com/940981765"
        }
    },
    methods: {
        trans() {
            console.log("hola");
        }
    },
    components: {
        LazyVimeo
    }
}
</script>

<style lang="scss" scoped>
    @import '@/assets/css/App.scss';

    $space-content: 12vw;

    .portada {
        height: 120vh;
        padding: 8vw  $space-content 14vw $space-content;
        position: relative;
        background: linear-gradient(to right, #17458F, #A05CBF);
        
        @include media("<phone") {
            height: 80vh;
            padding: 16vw 8vw;
        }

        @include media(">phone", "<tablet") {
            height: 70vh;
            padding: 16vw 8vw;
        }

        @include media(">=768px", "<=1024px") {
            height: 80vh;
            padding: 10vw 8vw;
        }

        @include media(">tablet", "<desktop") {
            height: 100vh;    
             padding: 10vw 8vw;    
        }

        .iYOnPx {
            height: 100% !important;
            background-color: #fff !important;
        }

        .trama {
            height: 70%;
            position: absolute;
            left: $space-content + 4vw;
            top: 0;
            bottom: 0;
            margin: auto 0;

            @include media("<phone") {
                left: -4vw;
                height: 100%;
            }
            
            @include media(">phone", "<tablet") {
                left: -4vw;
                height: 100%;
            }

            @include media(">=768px", "<=1024px") {
                left: -4vw;
                height: 100%;
            }

            @include media(">tablet", "<desktop") {
                left: $space-content;
                height: 80%;
            }

        }
        
        .logo-white {
            width: 40%;

            @include media("<phone") {
                width: 100%;   
                margin: 0 auto;
            }

            @include media(">phone", "<tablet") {
                width: 100%;   
                margin: 0 auto;
            }

            @include media(">=768px", "<=1024px") {
                width: 100%;   
                margin: 0 auto;
            }
        }

        .cont-info {
            position: relative;

            @include media("<phone") {
                display: flex;
                flex-flow: column;
                justify-content: center;
                align-items: center;
            }

            @include media(">phone", "<tablet") {
                display: flex;
                flex-flow: column;
                justify-content: center;
                align-items: center;   
            }

            @include media(">=768px", "<=1024px") {
                display: flex;
                flex-flow: column;
                justify-content: center;
                align-items: center;   
            }

            @include media(">tablet", "<desktop") {

            }

            .info-invite {
                left: 0;
                z-index: 4;
                justify-content: space-between;
                border-radius: 3em;
                padding: 3em;

                @include media("<phone") {
                    position: relative;
                    width: 100%;
                    padding: 1em;
                    border-radius: .5em;
                    margin-bottom: 6vw;
                }

                @include media(">phone", "<tablet") {
                    position: relative;
                    width: 100%;
                    padding: .9em;
                    border-radius: .5em;
                    margin-bottom: 6vw;
                }

                @include media(">=768px", "<=1024px") {
                    position: relative;
                    width: 100%;
                    padding: 1.5em;
                    border-radius: .5em;
                    margin-bottom: 4vw;
                }

                @include media(">tablet", "<desktop") {
                    height: 45%;
                    padding: 2em;
                    border-radius: 2em;
                }

                .text-left {
                    border-right: solid #fff .2em;
                    
                    @include media("<phone") {
                        border-right: solid #fff .05em;

                    }

                    @include media(">phone", "<tablet") {
                        border-right: solid #fff .05em;
                    }

                    @include media(">=768px", "<=1024px") {
                        border-right: solid #fff .1em;
                    }
                }
            }

            .cont-video {
                position: absolute;
                right: -$space-content;
                z-index: 2;
                width: 75%;
                
                @include media("<phone") {

                    position: relative;
                    width: 95%;
                    right: 0;
                    left: 0;
                }
                
                @include media(">phone", "<tablet") {
                    position: relative;
                    width: 95%;
                    right: 0;
                    left: 0;
                }

                @include media(">=768px", "<=1024px") {
                    position: relative;
                    width: 95%;
                    right: 0;
                    left: 0;
                }

                @include media(">tablet", "<desktop") {
                    right: -2em;
                    
                }
            }
        }
    }

    
</style>