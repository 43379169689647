export default {
    
    SET_PROJECTS(state, projects) {
        state.list = projects;
    },

    SET_QUERY(state, query) {
        state.filter.query = query;
    },

    SET_NEW_PROJECT(state, project) {
        state.list.unshift(project);
    },

    SET_PROJECT_DELETED(state, id_project) {
        let list = state.list;
        let aux = [];
        list.forEach(item => {
            if(item.id !== id_project) {
                aux.push(item);
            }
        });
        state.list = aux;
    },

    SET_PROJECTS_BY_CODE(state, projects) {
        state.projects_by_code = projects;
    },

    SET_QUERY_FOCUS(state, query) {
        state.filter_by_focus.query = query;
    },

    SET_PROJECT(state, project) {
        state.project = project;
    },

    SET_TOTAL_PROJECTS(state, projects) {
        state.total_projects = projects;
    },

    SET_RELATED_PROJECTS(state, projects) {
        state.related_projects = projects;
    }

}