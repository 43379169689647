import Vue from 'vue'
import VueRouter from 'vue-router'
import Preview from '../views/Preview.vue'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Dashboard from '../views/Dashboard.vue'
import Projects from '../views/Projects.vue'
import Project from '../views/Project.vue'

import { auth } from "@/config/firebase/auth.js"

Vue.use(VueRouter)

const routes = [
    {
        path: '/preview',
        name: 'Preview',
        component: Preview
    },
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
			requireAuth: true
		}
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard
    },
    {
        path: '/projects/:focus_code',
        name: 'Projects',
        component: Projects
    },
    {
        path: "/projects/:focus_code/:id_project",
        name: 'Project',
        component: Project
    }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, form , next) => {
    const requiresAuth = to.matched.some(route => route.meta.requiresAuth);
    if(requiresAuth) {
        auth.onAuthStateChange(user => {
            if(!user) {
                next("/login");
            } else { 
                next("/dashboard");
            }
        });
    } else {
        next();
    }

});

export default router
