var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-row-center start-row-center full-width messages",class:{
        'show': _vm.show,
        'hide': !_vm.show,
        'content-success': _vm.type === 0,
        'content-error': _vm.type === -1,
        'content-info': _vm.type === 1
    }},[_c('span',{staticClass:"text-medium text-white no-margin wdt-x9"},[_vm._v(_vm._s(_vm.message))]),_c('font-awesome-icon',{staticClass:"icon icon-white wdt-x1 icon-medium",attrs:{"icon":['fas', 'times-circle']},on:{"click":function($event){_vm.show = false;}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }