<template>
    <div class="navbar flex-row-center center-row-center content-white wdt-x10">

        <NavBarMobile
            @showNavBarMobile="showNavBarMobile"
            @goToSection="(index) => goToSection(index)"
            :class="nav_mobile ? 'show' : 'hide'"
        ></NavBarMobile>


        <div class="wdt-x2 flex-column-center center-column-start full-height cont-logo">
            <img 
                src="@/assets/images/logo.png"
                class="full-width logo"
                @click="goToSection(0)"
            />
        </div>
        <div class="wdt-x8 full-height flex-row-center end-row-center">

            <font-awesome-icon 
                :icon="['fa', 'bars']" 
                class="icon icon-primary icon-large hide menu-movil"
                @click="showNavBarMobile"
            />

            <div class="flex-row-center end-row-center wdt-x6 menu full-height">
                <span
                    v-for="(option, index) in menu"
                    :key="'option_' + index"
                    :class="active === index ? 'active' : ''"
                    class="no-margin text-subtitle-section text-medium text-primary menu_option flex-column-center center-column-center full-height"
                    @click="goToSection(index)"
                >
                    {{ option }}
                </span>
            </div>
            <!-- <div class="cont-translate content-white flex-column-center center-column-center" >
                <Translator/>
            </div> -->

        </div>
    </div>
</template>

<script>
import NavBarMobile from "@/utils/NavBarMobile.vue";

import Translator from "vue-gtranslate";
import "vue-gtranslate/translator.css"

    export default {

        data: () => {
            return {
                menu: [
                    "Inicio",
                    "Bienvenida",
                    "Proyectos",
                    "Contacto"
                ],

                nav_mobile: false
            };
        },

        methods: {
            goToSection(index) {
                this.$emit('goToSection', index);
                this.nav_mobile = false;
            },

            showNavBarMobile() {
                this.nav_mobile = !this.nav_mobile;
            }
        },

        props: {
            active: {
                type: Number,
                default:0
            }
        },

        components: {
            NavBarMobile,
            Translator

        }

    }
</script>

<style lang="scss" scoped>
    @import '@/assets/css/App.scss';

    .navbar {
        justify-content: space-between;
        height: 6vw;
        padding: 0 8vw;
        position: fixed;
        background: #fff;
        z-index: 999;


        @include media("<=phone") {
            height: 10vw;
        }

        @include media(">phone", "<tablet") {
            height: 14vw;
        }

        @include media(">=768px", "<=1024px") {
            height: 8vw;
        }

        .cont-translate{
            position: fixed;
            left: 0;
            top: 20vw;
            width: 4em;
            height: auto;
            padding: 1em .5em;
            border-top-right-radius: .6em;
            border-bottom-right-radius: .6em;

            @include media("<=phone") {
                top: 50vw;
                width: 2.8em;
            }

            @include media(">phone", "<tablet") {
                top: 50vw;
                width: 3em;
            }

            @include media(">=768px", "<=1024px") {
                top: 30vw;
                width: 4em;
            }



        }


        .menu-movil {

            @include media("<=phone") {
                display: flex !important;
                font-size: 4vw;
            }

            @include media(">phone", "<tablet") {
                display: flex !important;
                font-size: 6vw;
            }

            @include media(">=768px", "<=1024px") {
                display: flex !important;
                font-size: 4vw;
            }
        }

        .cont-logo {

            @include media("<=phone") {
                width: 40%;   
            }

            @include media(">phone", "<tablet") {
                width: 80%;
            }

            @include media(">=768px", "<=1024px") {
                width: 40%;
            }

            @include media(">tablet", "<desktop") {
                width: 20%;
            }
        }

        img.logo {
            cursor: pointer;
        }

        .menu {

            @include media("<=phone") {
                display: none !important;
            }

            @include media(">phone", "<tablet") {
                display: none !important;
            }

            @include media(">=768px", "<=1024px") {
                 display: none !important;
            }  

            @include media(">tablet", "<desktop") {
                width: 70%;
            }

            justify-content: space-between;

            .menu_option {
                cursor: pointer;
                transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
            }

            .menu_option.active {
                color: #960048;
                border-bottom: solid .2em #960048;
                transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
            }

            .menu_option:hover {
                transform: scale(1.1);
                color: #960048;
                transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
            }
        }
    }

</style>