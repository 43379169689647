<template>
    <div class="filters flex-column-center start-column-center">


        <FormulateInput 
            type="search"
            label="Buscar proyecto"
            placeholder="Buscar proyecto"
            v-model="search"
            :disabled="listProjectsByCode.length === 0"
        />
        
        <div class="flex-column-center subtitle-filters start-column-center wdt-x10 mg-bottom-x1 mg-top-x1">
            <span class="text-subtitle-section text-medium text-primary no-margin wdt-x10">
                CAUSAS
            </span>

            <img src="@/assets/images/rueda-causas.png" alt="" class="wdt-x6 mg-bottom-x2"/>

            <div 
                class="wdt-x10 focus_area_button mg-bottom-x1"
                v-for="(focus, index) in focuses"
                :key="'focus_' + index"
                :style="'background: ' + focus.color + ';'"
                @click="goToProjectsFocus(focus.code)"
            >
                <span class="text-medium text-white text-button full-size no-margin">
                    {{ focus.name }}
                </span>
                
            </div>
        </div>

        <div class="wdt-x10 content-grey-dd mg-top-x2 mg-bottom-x1 line"></div>
    </div>
</template>

<script>
import { list_focuses_area } from "@/assets/resources/formats_data.js";
import { mapGetters, mapState, mapActions } from "vuex";
    export default {

        data: () => {
            return {
                code: "",
                active: 0,
                focuses: []
            };
        },

        beforeMount() {
            this.loadDataByCode();
        },

        methods: {
            ...mapActions('projects', {
                searchProject: "searchProjectByFocus",
            }),

            loadDataByCode() {
                let list    = list_focuses_area();
                this.code   = this.$route.params.focus_code;

                list.forEach((item, index) => {
                    this.focuses.push(item);
                    if (item.code === this.code){
                        this.active = index;
                    }
                });
            },

            goToProjectsFocus(code) {
                this.$router.push({ name: "Projects", params: {focus_code: code}});
                this.$router.go();
            }
        },

        computed: {
            ...mapGetters('projects', {
                listProjectsByCode: "getListProjectsByCode"
            }),

            ...mapState({
                query: "projects"    
            }),

            search: {
                get() {
                    return this.query.filter_by_focus.query;
                },

                set(value) {
                    this.searchProject(value);
                }
            }
        }

    }
</script>

<style lang="scss" scoped>

@import '@/assets/css/App.scss';

.filters {
    overflow: scroll;
    min-height: 50vw;
    height: 50vw;
    max-height: 75vw;
    padding: 2vw 0;

    .line {
        height: .008em;
    }

    .focus_area_button {
        padding: 0.5vw 1vw;
        border-radius: .4em;
        cursor: pointer;
        transition: all 0.1s 0.1s ease-in-out;

        @include media("<phone") {
            padding: 2vw;
            margin-bottom: 2vw;
        }

        @include media(">phone", "<tablet") {
            padding: 2vw;
            margin-bottom: 2vw;
        }

        @include media(">=768px", "<=1024px") {
            padding: 1vw 2vw;
            font-size: 1em ;
        }
    }

    .focus_area_button:hover{
        transform: scale(0.98);
        transition: all 0.1s 0.1s ease-in;
    }
}
    
</style>