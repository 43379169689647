import Vue from 'vue';
import Vuex from 'vuex';

import users from "./modules/users/users";
import projects from "./modules/projects/projects";
import events from "./modules/events/events";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        users       : users,
        projects    : projects,
        events      : events        
    },
    strict: false
});
