export const getListProjects = state => {
    return state.list;
}

export const filterProjects = state => {
    let projects = state.list;
    if(state.filter.query.length > 0 ){
        return state.list.filter(project => 
            project.data.title.toLowerCase().includes(state.filter.query)
        );
    }
    return projects;
}

export const getListProjectsByCode = state => {
    return state.projects_by_code;
}

export const filterProjectsByFocus = state => {
    
    let projects = state.projects_by_code;

    if(state.filter_by_focus.query.length > 0 ){
        return state.projects_by_code.filter(project => 
            project.data.title.toLowerCase().includes(state.filter_by_focus.query)
        );
    }
    
    return projects;
}

export const getProject = state => {
    return state.project;
}

export const countProjects = state => {
    let projects = state.total_projects;

    let focuses = [ 
        {
            code: "peace", 
            total: 0
        },
        {
            code: "diseases", 
            total: 0
        },
        {
            code: "water", 
            total: 0
        },
        {
            code: "health", 
            total: 0
        },
        {
            code: "education", 
            total: 0
        },
        {
            code: "economy", 
            total: 0
        },
        {
            code: "environment",
            total: 0
        }
    ];

    projects.forEach(project => {
        let code = project.data.focus_area.code;

        if      (code === "peace")      focuses[0].total++;
        else if (code === "diseases")   focuses[1].total++;
        else if (code === "water")      focuses[2].total++;
        else if (code === "health")     focuses[3].total++;
        else if (code === "education")  focuses[4].total++;
        else if (code === "economy")    focuses[5].total++;
        else focuses[6].total++;
        
    });

    return focuses;
}

export const getRelatedProject = state => {
    return state.related_projects;
}

