import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store/store.js'
import { auth } from "@/config/firebase/auth.js";
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueFormulate from '@braid/vue-formulate'
import { es } from '@braid/vue-formulate-i18n'
import '../node_modules/@braid/vue-formulate/themes/snow/snow.scss';
import '../src/styles/formulate.scss';
import { library } from "@fortawesome/fontawesome-svg-core";
import LazyYoutube from "vue-lazytube";
import ICS from 'vue-ics';
import SlitherSlider from 'slither-slider';

import VueI18n from 'vue-i18n';
import i18n from './lang'

const moment = require('moment');
require('moment/locale/es');

var VueScrollTo = require('vue-scrollto');

import { 
    faUser,
    faLock,
    faCopyright,
    faHome,
    faProjectDiagram,
    faSignOutAlt,
    faPlus,
    faEdit,
    faTrashAlt,
    faFilter,
    faTimesCircle,
    faArrowAltCircleDown,
    faCalendarAlt,
    faPhoneAlt,
    faEnvelope,
    faMarker,
    faMapMarkedAlt,
    faHandHolding,
    faHandsHelping,
    faArrowLeft,
    faShareAlt,
    faHamburger,
    faBars
} from "@fortawesome/free-solid-svg-icons";

import {
    faFacebook,
    faTwitter,
    faInstagram
} from "@fortawesome/free-brands-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add( 
    faUser, 
    faLock,
    faCopyright,
    faHome,
    faProjectDiagram,
    faSignOutAlt,
    faPlus,
    faEdit,
    faTrashAlt,
    faFilter,
    faTimesCircle,
    faArrowAltCircleDown,
    faCalendarAlt,
    faFacebook,
    faTwitter,
    faInstagram,
    faPhoneAlt,
    faEnvelope,
    faMarker,
    faMapMarkedAlt,
    faFilter,
    faHandHolding,
    faHandsHelping,
    faArrowLeft,
    faShareAlt,
    faHamburger,
    faBars
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("LazyYoutube", LazyYoutube);

Vue.config.productionTip = false
axios.defaults.headers.common["Access-Control-Allow-CrossOrigin"] = "*";

Vue.use(VueAxios, axios);
Vue.use(ICS);
Vue.use(SlitherSlider);
Vue.use(VueScrollTo);
Vue.use(VueI18n);

Vue.use(VueFormulate, {
    plugins: [es],
    locale: 'es'
});

Vue.use(require('vue-moment'), {
    moment
});


let app = null;

auth.onAuthStateChanged(async user => {
    if(user) {
        let response = await store.dispatch('users/initializeData', user.uid);
        if(response.code !== 0) await auth.signOut();
    } else {
        await store.dispatch('users/nullableData');
    }

    if(!app){ 
        app = new Vue({
            router,
            i18n,
            store,
            render: h => h(App)
        }).$mount('#app')
    }
});



