<template>
    <div class="projects">

        <TopBar
            @selectActionToForm="(action_type) => selectActionToForm(action_type)"
        ></TopBar>

        <div class="pd-left-x2 pd-right-x2 hgh-x9">
            <div class="cont-search flex-column-center start-columna-start pd-top-x2 pd-bottom-x2">
                <span class="text-medium text-primary text-subtitle wdt-x5">Búsqueda de proyectos</span>
                <div class="wdt-x8">
                    <FormulateInput 
                        type="search"
                        placeholder="Buscar proyecto por título"
                        v-model="search"
                    />
                </div>
            </div>
            
            <Loader :active="loader.active" :message="loader.text"></Loader>
            <Messages :active="messages.active" :message="messages.text" :type="messages.type"></Messages>

            <div class="cont-table flex-column-center start-column-center">
                <ItemTable
                    v-for="project in filterProjects"
                    :key="project.id"
                    :project="project"
                    @selectActionToForm="(action_type, data_project) => selectActionToForm(action_type, data_project)"
                ></ItemTable>
            </div>

            <div 
                v-if="action !== null"
                class="full-width content-white cont-form hgh-x3 pd-left-x2 pd-right-x2 pd-top-x2 pd-bottom-x2"
                :class="active_form ? 'animation-in' : 'animation-out'"
            >
                <FormProject 
                    :action = "action"
                    :data = "project_selected"
                    @formClosed="formClosed"
                ></FormProject>
            </div>

        </div>

    </div>
</template>

<script>
    import { mapGetters, mapState, mapActions } from "vuex";
    import TopBar from "@/components/TopBar.vue";
    import ItemTable from "@/utils/ItemTable.vue";
    import FormProject from "@/components/FormProject.vue"

    import Loader from "@/utils/Loader.vue";
    import Messages from "@/utils/Messages.vue";


    export default {
        name: "RegisterProject",
        data: () => {
            return {
                active_form: false,
                action: null,
                project_selected: {},
                loader: {
                    active: false,
                    text: "Ingresando..."
                },
                messages: {
                    active: false,
                    text: "",
                    type: 0
                }
            };
        },

        computed: {
            
            ...mapState({
                query: "projects"    
            }),

            ...mapGetters('projects', {
                projects: "getListProjects",
                filterProjects: "filterProjects"
            }),
            
            search: {
                get() {
                    return this.query.filter.query;
                },

                set(value) {
                    this.searchProject(value);
                }
            }
        },

        components: {
            TopBar,
            ItemTable,
            FormProject,
            Loader,
            Messages
        },

        methods: {
            ...mapActions('projects', {
                searchProject: "searchProject",
                deleteProject: "deleteProject"
            }),

            selectActionToForm(action_type, data_project) {
                this.action = action_type; 

                switch (action_type) {
                    case 0:
                        this.openFormToRegister();
                        break;
                    case 1:
                        this.openFormToEdit(data_project);
                        break;
                
                    case 2:
                        this.openModalToDelete(data_project);
                        break;
                
                    default:
                        break;
                }
            },

            openFormToRegister() {
                this.validateStatusForm();
            },

            openFormToEdit(project) {
                this.project_selected = project;
                this.validateStatusForm();
            },

            async openModalToDelete(project) {
                this.project_selected = project;

                this.loader.active = true;
                this.messages.active = false;
                this.loader.text = "Eliminando, por favor espere..."

                this.project_selected.data.active = false;

                let response = await this.deleteProject(this.project_selected);
                this.setMessage(true, response.message, response.code);
                this.loader.active = false;

            },

            validateStatusForm(){
                if(this.active_form) this.active_form = false;
                else this.active_form = true;
            },

            formClosed() {
                this.active_form        = !this.active_form;
                this.action             = null;
                this.project_selected   = null;
            },

            setMessage(isActive, message, type) {
                this.messages.active = isActive;
                this.messages.text = message;
                this.messages.type = type;
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/css/App.scss';

    .projects {
        position: relative;
        height: 100%;

        .cont-search {
            justify-content: space-between;
        }

        .cont-table {
            max-height: 70%;
            overflow: scroll;
        }

        .cont-form {
            position: absolute;
            top: 10%;
            left: 0;
            height: 90%;
        }
    }
</style>    