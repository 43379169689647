import * as getters from './getters';
import * as actions from './actions';
import mutations from './mutations';


const state = {
    list:[],
    filter: {
        query: '',
        status: true
    },
    projects_by_code: [],
    filter_by_focus: {
        query: '',
        status: true
    },
    project: {},
    total_projects: [],
    related_projects: []
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}