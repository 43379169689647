<template>
    <div class="focuses flex-column-center center-column-center" id="projects">

        <span class="text-medium text-subtitle-section">{{ title }}</span>
        <span class="text-medium text-subtitle-section">{{ subtitle }}</span>

        <div 
            class="wdt-x10 cont-focuses-area flex-row-center" 
            v-if="focuses.length > 0"
        >
            <div 
                class="focus-area  animate_active flex-column-center center-column-center"
                v-for="(focus_area, index) in focuses"
                :key="'focus_area_' + index"
                :class="{
                    'first': index === 0,
                    'last': index === focuses.length - 1,
                }"
                @click="changeFocus(index)"

            >
                <div class="identy-focus flex-column-center center-column-center hgh-x8">

                    <div class="flex-column-center center-column-center hgh-x8 cont-icon-focus">
                        <img 
                            :src="'img/' + focus_area.image" 
                            :alt="focus_area.tag" 
                            class="wdt-x8 icon-focus" 
                        />
                    </div>

                    <span class="text-light text-content title-icon hgh-x2">{{ focus_area.name }}</span>

                </div>

                <div class="identy-bar flex-column-center center-column-center hgh-x2 wdt-x10">
                    <div 
                        class="wdt-x04 full-height fade-in-left"
                        :class="active === index ? 'show background-focus-' + focus_area.code : 'hide'"
                    >
                    </div>
                </div>
            </div>

            <div 
                class="fade-in-left wdt-x10 content-white hgh-x2 focus-info flex-row-center center-row-start" 
                :style="'border-color: ' +  focuses[active].color + '!important'"
            >

                <img 
                    :src="'img/' + focuses[active].image" 
                    :alt="focuses[active].tag" 
                    class="back-icon-trama" 
                />

                <div class="wdt-x5 info-focus-left flex-column-center center-column-start hgh-x10 fade-in-left">
                    
                    <span 
                        :style="'color: ' + focuses[active].color"
                        class="text-medium text-title mg-bottom-x2"
                    >
                        {{ focuses[active].name }}
                    </span>

                    <span class="text-light text-subtitle-section">
                        {{ focuses[active].description }}
                    </span>
                </div>
                <div class="wdt-x5 info-focus-right flex-row-center center-row-center hgh-x10 fade-in-left">
                    <div class="wdt-x6 flex-column-center center-column-center hgh-x10 cont-icon-info">

                        <div class="flex-column-center center-column-center hgh-x10 ">
                            <img 
                                :src="'img/' + focuses[active].image" 
                                :alt="focuses[active].tag" 
                                class="full-size" 
                            />
                        </div>
                        
                    </div>
                    <div class="wdt-x4 flex-column-center center-column-start hgh-x10 cont-buttons-info">

                        <span 
                            :style="'color: ' + focuses[active].color"
                            class="text-number text-medium no-margin"
                            translate="no"
                        >
                            {{ countProjects[active].total }}
                        </span>

                        <span
                            :style="'color: ' + focuses[active].color"
                            class="text-subtitle text-medium"
                            v-if="focuses[active].total === 1"
                        >
                            proyecto
                        </span>

                        <span
                            :style="'color: ' + focuses[active].color"
                            class="text-subtitle text-medium"
                            v-else
                        >
                            proyectos
                        </span>

                        <button 
                            class="text-white text-medium text-button"
                            :style="'background: ' + focuses[active].color"
                            @click="goToViewProjects(focuses[active].code)"
                            v-if="focuses[active].total === 1"
                        >
                            VER PROYECTO
                        </button>

                        <button 
                            class="text-white text-medium text-button"
                            :style="'background: ' + focuses[active].color"
                            @click="goToViewProjects(focuses[active].code)"
                            v-else
                        >
                            VER PROYECTOS
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

    <script>
        
        import { list_focuses_area } from "@/assets/resources/formats_data.js";
        import { mapGetters, mapActions } from "vuex";
        
        export default {
            name: 'Focuses',
        
            data() {
                return {
                    title: "Rotary tiene 7 causas globales por las cuales nos esforzamos en trabajar.",
                    subtitle: "Revisa los proyectos que se desarrollarán por cada causa rotaria.",
                    button: "VER PROYECTOS",
                    focuses: [],
                    active: 0,
                    animate_active: true
                };
            },
        
            beforeMount() {
                this.initialData();
            },
        
            methods: {
                ...mapActions('projects', {
                    getTotalProjects: "getTotalProjects"
                }),

                async initialData() {
                    await this.getTotalProjects();
                    let list = list_focuses_area();

                    list.forEach((item, index) => {
                        item.total = this.countProjects[index].total;
                    });
                    this.focuses = list
                },

                changeFocus(index) {
                    this.active = index;
                },

                goToViewProjects(code) {
                    this.$router.push({
                        path: "/projects/" + code ,
                        params: {
                            focus_code: code
                        }
                    })
                }
            },

            computed: {
                ...mapGetters('projects', {
                    countProjects: "countProjects"
                })
            }
        };
    </script>

<style lang="scss" scoped>
    @import '@/assets/css/App.scss';

    .focuses {
        position: relative;
        padding: 8vw 4vw;
        background: #e1ebf2;

        .cont-focuses-area {
            padding: 2vw 0;
            flex-flow: row wrap;
            justify-content: space-evenly;
            align-items: center;

            .focus-area {
                width: calc(100% / 8);
                margin: 0 0.5vw;
                height: 20vw;
                justify-content: space-between;
                cursor: pointer;
                transition: all 0.1s 0.1s ease-in-out;

                .identy-focus {
                    @include media("<phone") {
                        height: 100%;
                    }

                    @include media(">phone", "<tablet") {
                        height: 100%;
                    }
                    @include media(">=768px", "<=1024px") {
                        height: 100%;
                    }

                    .cont-icon-focus {
                        @include media("<phone") {
                            height: 70%;
                        }

                        @include media(">phone", "<tablet") {
                            height: 70%;
                        }
                        @include media(">=768px", "<=1024px") {
                            height: 70%;
                        }
                    }

                    .title-icon {
                        @include media("<phone") {
                            height: 30%;
                            margin: 0;
                            font-size: .6em !important;
                        }

                        @include media(">phone", "<tablet") {
                            height: 30%;
                            margin: 0;   
                        
                        }

                        @include media(">=768px", "<=1024px") {
                            height: 30%;
                            margin: 0;   
                        }
                    }
                }

                .identy-bar {
                    @include media("<phone") {
                        display: none;
                    }

                    @include media(">phone", "<tablet") {
                        display: none;
                        
                    }
                     
                    @include media(">=768px", "<=1024px") {
                        display: none;
                    }
                }

                @include media("<phone") {
                    width: calc((100% / 3) - 1vw );
                    margin: 0 0.5vw;
                    height: 35vw;
                }

                @include media(">phone", "<tablet") {
                    width: calc((100% / 3) - 1vw );
                    margin: 0 0.5vw;
                    height: 35vw;
                        
                }

                @include media(">=768px", "<=1024px") {
                    width: calc((100% / 3) - 1vw );
                    margin: 0 0.5vw;
                    height: 22vw;

                }

                

                .icon-focus {
                    @include media("<phone") {
                        width: 50%;
                    }

                    @include media(">phone", "<tablet") {
                        width: 50%;
                        
                    }

                    @include media(">=768px", "<=1024px") {
                        width: 50%;
                        
                    }

                    @include media(">tablet", "<desktop") {
                        
                    } 
                }


                

                .title-icon {
                    text-align: center;
                }
            }

            .focus-area:hover {
                transform: scale(0.98);
                transition: all 0.1s 0.1s ease-in;
            }

            .focus-area.first {
                margin: 0;
            }

            .focus-area.last {
                margin: 0;
            }

            .focus-info {
                padding: 4vw 2vw;
                height: 24vw;
                border: solid .3em; 
                border-radius: .8em;
                position: relative;
                overflow: hidden;

                @include media("<phone") {
                    height: auto;
                    padding: 1.2em 1em;
                    display: flex !important;
                    flex-flow: column !important;
                    justify-content: center !important;
                    align-items: center !important;
                    border-radius: .4em;
                    border: solid .1.5em; 

                }
                @include media(">phone", "<tablet") {
                    height: auto;
                    padding: 1.2em 1em;
                    display: flex !important;
                    flex-flow: column !important;
                    justify-content: center !important;
                    align-items: center !important;
                    border-radius: .4em;
                    border: solid .1.5em; 
                    
                }

                @include media(">=768px", "<=1024px") {
                    height: auto;
                    padding: 1.2em 1em;
                    display: flex !important;
                    flex-flow: column !important;
                    justify-content: center !important;
                    align-items: center !important;
                    border-radius: .4em;
                    border: solid .1.5em; 
                }

                @include media(">tablet", "<desktop") {
                    height: 30vw;
                } 

                .back-icon-trama {
                    position: absolute;
                    left: -6vw;
                    bottom: -6vw;
                    width: 30%;
                    opacity: 0.06;
                    filter: grayscale(100);

                    @include media("<phone") {
                        display: none;
                    }

                    @include media(">phone", "<tablet") {
                        display: none;
                        
                    }
                    @include media(">=768px", "<=1024px") {
                        display: none;
                    }

                }

                .info-focus-left {
                    padding-left: 8vw;
                    padding-right: 2vw;

                    @include media("<phone") {
                        padding: 0;
                        width: 100%;
                        margin-bottom: 1em;
                    }

                    @include media(">phone", "<tablet") {
                        padding: 0;
                        width: 100%;
                        margin-bottom: 1em;
                        
                    }

                    @include media(">=768px", "<=1024px") {
                        padding: 0;
                        width: 100%;
                        margin-bottom: 1em;

                    }
                }

                .info-focus-right {
                    @include media("<phone") {
                        padding: 0;
                        width: 100%;
                    }

                    @include media(">phone", "<tablet") {
                        padding: 0;
                        width: 100%;
                        
                    }

                    @include media(">=768px", "<=1024px") {
                        padding: 0;
                        width: 100%;

                    }

                    .cont-icon-info {
                        width: 50%;
                        img {
                            @include media("<phone") {
                                width: 70% !important;
                                height: 70% !important;
                            }

                            @include media(">phone", "<tablet") {
                                width: 70% !important;
                                height: 70% !important;
                        
                            }
                            @include media(">=768px", "<=1024px") {
                                
                                width: 85% !important;
                                height: 85% !important;
                            }

                            @include media(">tablet", "<desktop") {
                                width: 100% !important;
                                height: 65% !important;
                            }
                        }
                    }

                    .cont-buttons-info {
                        width: 50%;
                    }

                }
            }
        }
    }


</style>

