export default {
    config: {
        apiKey: "AIzaSyCQ58Hti8qskNK8MpKHXGnlGOKijf0G8dc",
        authDomain: "projects-fair-rotary.firebaseapp.com",
        projectId: "projects-fair-rotary",
        storageBucket: "projects-fair-rotary.appspot.com",
        messagingSenderId: "240461699940",
        appId: "1:240461699940:web:52826709e7057f76d13871",
        measurementId: "G-3X6QQNDT3K"
    }
};
  