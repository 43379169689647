<template>
  	<div class="aditional wdt-x4 flex-column-center start-column-start pd-left-x1">

		<div class="wdt-x10 flex-column-center start-column-start mg-bottom-x1 change-to-column">


			<span class="wdt-x10 text-medium text-grey mg-bottom-x1 text-subtitle-section">
                Acerca del proyecto
            </span>

            <span class="text-light text-content description mg-bottom-x2">
                {{ project.description}}
            </span>

            <span class="wdt-x10 text-medium text-grey mg-bottom-x1 text-subtitle-section">
                Ubicación del proyecto
            </span>

            <span class="text-light text-content">
                {{ project.location }}
            </span>


		</div>

		<div class="wdt-x10 flex-column-center start-column-start">

			<span class="wdt-x10 text-medium text-grey mg-bottom-x1 resp-title-section text-subtitle-section">Presentación del proyecto</span>

			<div class="wdt-x10 content-grey-d hgh-x10 cont-video flex-column-center center-column-center">
				<span
					class="text-light text-button wdt-x8 text-grey no-margin"
					v-if="project.video === ''"
				>
					El video estará disponible después del día de la presentación
				</span>

				<iframe
					v-else
					width="100%"
					height="100%"
					:src="project.video"
					:title="'Presentación del proyecto: ' + project.title"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowfullscreen
				></iframe>
			</div>
		</div>
  	</div>
</template>

<script>
	export default {
		data: () => {
			return {};
		},

		props: {
            project: {
                type: Object,
                default: () => {
                    return { };
                }
            }
        },

        methods: {
            openURL(url) {
                window.open(url, "_blank");
            }
        },
	};
</script>

<style lang="scss" scoped>
    @import "@/assets/css/App.scss";
    
    .aditional {

        @include media("<phone") {
            width: 100% !important;
            margin-top: 4vw;
        }

        @include media(">phone", "<tablet") {
            width: 100% !important;
            margin-top: 4vw;
        }

        @include media(">=768px", "<=1024px") {
            width: 100% !important;
            margin-top: 4vw;
        }

        .description {
            min-height: auto;
            max-height: 14em;
            padding-right: .5em;
            overflow: scroll;
        }

        .resp-title-section {
            @include media("<phone") {
                margin-bottom: 3vw;
                margin-top: 2vw;
            }

            @include media(">phone", "<tablet") {
                margin-bottom: 3vw;
                margin-top: 2vw;
            }

            @include media(">=768px", "<=1024px") {
                margin-bottom: 3vw;
                margin-top: 2vw;
            }
        }


        .cont-video {
            height: 270px;

            @include media("<phone") {
                height: 150px;
            }

            @include media(">phone", "<tablet") {
                height: 270px;
            }

            @include media(">=768px", "<=1024px") {
                height: 270px;
            }
        }
    }
</style>