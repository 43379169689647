<template>
    <div 
        class="card-project flex-column-center start-column-start shadow"
        @click="goToProject(project.data.focus_area.code, project.id)"
    >
        <div class="hgh-x8 wdt-x10 mg-bottom-x2 cont-img flex-column-center center-column-center">
            <img :src="returnImage(project.data.focus_area.code)" class="wdt-x10" />
        </div>

        <div class="hgh-x2 wdt-x10 cont-info">

            <span class="text-medium text-content wdt-x10 title-project no-margin">
                {{ project.data.title }}
            </span>
            
            <div class="wdt-x10 hgh-x03 content-grey-d mg-top-x05 mg-bottom-x05"></div>

            <span class="text-medium text-content name-club no-margin wdt-x10 uper">
                {{ project.data.club.name }}
            </span>
        </div>

    </div>
</template>

<script>
    export default {
        data: () => {
            return {
                
            };
        },

        props: {
            project: {
                type: Object,
                default: () => {
                    return {};
                }
            }
        },


        methods: {
            returnImage(code) {
                var images = require.context('@/assets/images/', false, /\.jpeg$/);
                return images('./home-' + code + ".jpeg");
            },

            returnIcon(code) {
                var images = require.context('@/assets/images/', false, /\.png$/);
                return images('./icon-' + code + ".png");
            },

            goToProject(code, projectID) {
                this.$router.push({
                    path: "/projects/" + code + "/" + projectID,
                    params: {
                        focus_code: code,
                        id_project: projectID
                    }
                }),
                this.$router.go();
            }
        },
    }
</script>

<style lang="scss" scoped>

@import '@/assets/css/App.scss';

.card-project {
    width: calc((100% / 3) - 2vw);
    height: 100%;
    margin: 0 1vw;
    border-radius: .4em;
    padding: 1vw 1.5vw;
    transition: all 0.2s 0.2s ease-out;

    @include media("<phone") {
        width: calc((100% / 2) - 2vw);
        height: auto !important;
        padding: 4vw;
        margin-bottom: 4vw !important;
    }

    @include media(">phone", "<tablet") {
        width: calc((100% / 2) - 2vw);
        height: auto !important;
        padding: 2vw;
        margin-bottom: 4vw !important;
    }

    @include media(">=768px", "<=1024px") {
        width: calc((100% / 2) - 2vw);
        height: auto !important;
        padding: 2vw;   
        margin-bottom: 2vw !important;
    }

    .cont-img {
        overflow: hidden;

        img {
            filter: opacity(0.5);
        }

    }

    .cont-info {
        position: relative;

        .icon-focus {
            position: absolute;
            right: 1vw;
            top: -90%;
            width: 30%;
            z-index: 999;

            @include media(">phone", "<tablet") {
                top: -140%;
                width: 30%;
            }

            @include media(">=768px", "<=1024px") {
                top: -140%;
                width: 30%;
            }
        }

        .title-project {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .name-club {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

.card-project:hover {
    cursor: pointer;
    transform: scale(1.05);
    transition: all 0.2s 0.2s ease-in;
}

.card-project.first {
    margin-left: 0;
}

.card-project.last {
    margin-right: 0;
}
    
</style>