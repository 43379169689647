import { db } from "@/config/firebase/db";
import { storage } from "@/config/firebase/storage";
import { inListData, inListDataRelated, format_images_list } from "@/assets/resources/formats_data.js"

export const getProjects = async ({ commit }) => {
    try {
        //.where('registered_by', '==', id_user)
        let response = await db.collection("projects").where('active', '==', true).get();

        if (!response.empty) {
            let list_projects = inListData(response.docs);
            commit('SET_PROJECTS', list_projects);
            return {
                code: 0,
                message: "Listado de proyectos"
            }
        } else {
            return {
                code: 0,
                message: "No hay proyectos registrados"
            };
        }

    } catch (error) {
        return {
            code: -1,
            message: "No se pudo obtener los proyectos"
        }
    }
}

export const searchProject = async ({ commit }, query) => {
    commit('SET_QUERY', query);
}

export const registerProject = async ({ commit }, data_project) => {
    try {

        let snapshot = await db.collection('projects').add(data_project);

        if (snapshot.id) {
            let project = {
                id: snapshot.id,
                data: data_project
            };
            commit('SET_NEW_PROJECT', project);
            return {
                code: 0,
                message: "Proyecto guardado correctamente"
            }
        }
    } catch (error) {
        return {
            code: -1,
            message: "No se pudo guardar el proyecto, intente más tarde " + error
        }
    }

}

export const updateProject = async ({ commit }, data_project) => {
    try {

        await db.collection('projects').doc(data_project.id).set(data_project.data);

        return {
            code: 0,
            message: "Proyecto actualizado correctamente"
        }

    } catch (error) {
        return {
            code: -1,
            message: "No se pudo actualizar el proyecto, intente más tarde " + error
        }
    }
}

export const deleteProject = async ({ commit }, data_project) => {
    try {

        await db.collection('projects').doc(data_project.id).set(data_project.data);

        commit('SET_PROJECT_DELETED', data_project.id);

        return {
            code: 0,
            message: "Proyecto eliminado correctamente"
        }

    } catch (error) {
        return {
            code: -1,
            message: "No se pudo actualizar el proyecto, intente más tarde " + error
        }
    }
}

export const getProjectByCode = async ({ commit }, code) => {
    try {

        let response = await db.collection('projects').where("focus_area.code", "==", code).where("active", "==", true).get();

        if (!response.empty) {

            let list_projects = inListData(response.docs);

            commit('SET_PROJECTS_BY_CODE', list_projects);

            return {
                code: 0,
                message: "Proyectos encontrados"
            }
        } else {
            return {
                code: 1,
                message: "No hay proyectos registrados para esta categoría"
            }
        }

    } catch (error) {
        return {
            code: -1,
            message: "No se pudo actualizar el proyecto, intente más tarde " + error
        }
    }
}

export const searchProjectByFocus = async ({ commit }, query) => {
    commit('SET_QUERY_FOCUS', query);
}

export const getProjectByID = async ({ commit }, projectID) => {

    try {

        let doc = db.collection("projects").doc(projectID).get();
        let data_project = {};

        doc.then(project => {
            data_project.id = project.id,
                data_project.data = project.data()
            commit('SET_PROJECT', data_project);
        });

        return {
            code: 0,
            message: "Información del proyecto obtenido"
        }

    } catch (error) {
        return {
            code: -1,
            message: "No se pudo obtener el proyecto " + error
        }
    }
}

export const getTotalProjects = async ({ commit }) => {
    try {
        let response = await db.collection("projects").where('active', '==', true).get();

        if (!response.empty) {

            let list_projects = inListData(response.docs);

            commit('SET_TOTAL_PROJECTS', list_projects);

            return {
                code: 0,
                message: "Listado de proyectos"
            }

        } else {
            return {
                code: 0,
                message: "No hay proyectos registrados"
            };
        }

    } catch (error) {
        return {
            code: -1,
            message: "No se pudo obtener la cantidad de proyectos " + error
        }
    }
}

export const getRelatedProjects = async ({ commit }, dataToSearch) => {
    try {

        let { code, id_project } = dataToSearch;

        let response = await db.collection("projects").where("focus_area.code", "==", code).where('active', '==', true).limit(3).get();

        if (!response.empty) {

            let list_projects = inListDataRelated(response.docs, id_project);

            commit('SET_RELATED_PROJECTS', list_projects);

            if (list_projects.length === 0) {
                return {
                    code: 1,
                    message: "No hay proyectos relacionados"
                }
            } else {
                return {
                    code: 0,
                    message: "Listado de proyectos relacionados"
                }
            }



        } else {
            return {
                code: 0,
                message: "No hay proyectos relacionados"
            };
        }

    } catch (error) {
        return {
            code: -1,
            message: "No se pudo obtener proyectos relacionados " + error
        }
    }
}

export const uploadImages = async ({ commit }, dataReceived) => {
    try {

        let { list, titleProject } = dataReceived;
        let ref = storage.ref();
        let urls = [];

        list.forEach(async image => {
            let path = 'images/' + titleProject + "/" + image.name;
            const refImg = ref.child(path);
            let response = await refImg.putString(image.path, 'data_url');
            if (response.state === "success") {
                let url = await response.ref.getDownloadURL();
                urls.push(url);
            }
        });

        return {
            code: 0,
            urlsImages: urls
        };

    } catch (error) {
        return {
            code: -1,
            message: "No se pudo cargar las imágenes " + error
        }
    }

}