<template>
    <div class="topbar hgh-x1 pd-left-x2 pd-right-x2 content-primary box-shadow flex-row-center center-row-center">

        <span class="text-medium text-white text-subtitle wdt-x5">{{ title }}</span>

        <div class="flex-row-center end-row-center options wdt-x5">
            <button 
                class="content-yellow text-content text-medium text-primary"
                @click="selectAction(0)"
            >
                <font-awesome-icon 
                    :icon="['fas', 'plus']" 
                    class="icon icon-primary" 
                />
                Nuevo proyecto
            </button>
        </div>
    </div>
</template>


<script>
export default {
    name: "TopBar",
    data: () => {
        return {
            title: "Registro de Proyectos Rotary Ecuador"
        };
    },
    methods: {
        selectAction(action) {
            this.$emit('selectActionToForm',  action);
        }
    },

}
</script>

<style lang="scss" scoped>
    @import '@/assets/css/App.scss';

    .topbar {
        justify-content: space-between !important;

        button {
            margin-left: .8em;
        }

        span {
            text-transform: uppercase;
        }
    }

</style>