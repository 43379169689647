import { auth } from "@/config/firebase/auth";
import { db } from "@/config/firebase/db";

export const nullableData = async({ commit }) => {  
    commit('SET_DATA_USER', { status: false, id: null, data: null });
}

export const initializeData = async({ commit }, userID) => { 
    try {
        let user = await db.collection("users").doc(userID).get();
        if( user.exists ) {
            commit(
                'SET_DATA_USER', 
                { 
                    status: true, 
                    id: userID, 
                    data: user.data() 
                }
            );

            return {
                code: 0,
                message: "Se pudo obtener el usuario"
            };
        } else { 
            return {
                code: -1,
                message: "No se pudo obtener el usuario"
            };
        }
        
    } catch (error) {
        return {
            code: -1,
            message: error
        };
    }
}

export const signIn = async ({ commit }, payload) => {
    try {

        let { email, password } = payload;
        let response = await auth.signInWithEmailAndPassword(email, password);
        
        if(response.user) {
            let data_user = await db.collection("users").doc(response.user.uid).get();
            if(data_user.exists) {
                commit('SET_DATA_USER', { status: true, id: data_user.id, data: data_user.data() });
                return {
                    code: 0,
                    message: "¡Bienvenido!"
                };
            }
        }
    } catch (error) {
        if(error.code === "auth/user-not-found") {
            return {
                code: -1,
                message: "No existe un usuario con este correo"
            }
        } else if(error.code === "auth/wrong-password") {
            return {
                code: -1,
                message: "La contraseña no es correcta"
            }
        } else {
            return {
                code: -1,
                message: error
            };
        }
    }
}


export const signOut = async ({ commit }) => {
    try {
        commit('RESTORE_DATA_USER');
    } catch (error) {
        return {
            code: -1,
            message: error
        };
    }
}