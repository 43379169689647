<template>
    <div class="invitation wdt-x10 flex-row-center center-row-center" id="invitation">
        <img src="@/assets/images/trama-color-fair.png" alt="" class="trama"/>
        <div class="wdt-x5 full-height flex-column-center center-column-start presentation">
            <span class="text-primary text-title text-medium">
                {{ title }}
            </span>

            <span class="text-light text-subtitle-section mg-bottom-x2">
                {{ subtitle_a }}
            </span>
        </div>
        <div class="wdt-x5 full-height video ">
            <LazyVimeo  
            ref="vimeoLazyVideo"
            :src="videoVimeo"
            max-width="100%"
            max-height="100%"
            aspect-ratio="1:1"
            thumbnail-quality="standard"
            customThumbnail="img/portada_video.png"
            />    
        </div>
    </div>
</template>

<script>
import { LazyVimeo } from "vue-lazytube";

export default {
    name: 'Invitation',
    data() {
        return {
            title: "¡Te esperamos!",
            subtitle_a: "No te pierdas la oportunidad de ser parte de la XVIII Feria de Proyectos Rotary Ecuador 2024",
            subtitle_b: "Sé parte de nuestra XVIII Feria de Proyectos Rotary Ecuador 2024",
            videoVimeo: "https://vimeo.com/948874662",
        };
    },

    mounted() {
        
    },

    methods: {
        openURL(url) {
            window.open(url, "blank");
        }
    },

    components: {
        LazyVimeo
    }
};
</script>

<style lang="scss" scoped>
    @import '@/assets/css/App.scss';


    .rXUqC {
        background-color: #fff !important;
        height: 100%;
    }

    $space-content: 12vw;

    .invitation {
        position: relative;
        padding: 12vw $space-content 4vw $space-content;

        @include media("<phone") {
            display: flex !important;
            flex-flow: column !important;
            justify-content: center !important;
            align-items: center !important; 
            padding: 12vw 8vw 8vw 8vw;
        }

        @include media(">phone", "<tablet") {
            display: flex !important;
            flex-flow: column !important;
            justify-content: center !important;
            align-items: center !important; 
            padding: 12vw 8vw 8vw 8vw;
        }

        @include media(">=768px", "<=1024px") {
            display: flex !important;
            flex-flow: column !important;
            justify-content: center !important;
            align-items: center !important; 
            padding: 10vw 8vw 8vw 8vw;
        }

        @include media(">tablet", "<desktop") {

        }

        .cont-button-download{ 
            justify-content: space-between;

            @include media("<phone") {
                display: flex !important;
                flex-flow: column !important;
                justify-content: center !important;
                align-items: center !important; 
                padding: 1em 0 2em 0;
            }

            @include media(">phone", "<tablet") {
                display: flex !important;
                flex-flow: column !important;
                justify-content: center !important;
                align-items: center !important; 
                padding: 1em 0 2em 0;
            }

            @include media(">=768px", "<=1024px") {
                padding: 0em 0 2em 0;
            }


            .text-button {
                @include media("<phone") {
                    width: 100%;
                    margin: 0;
                }

                @include media(">phone", "<tablet") {
                    width: 100%;
                    margin: 0;
                }
            }

            .text-button.first {
                @include media("<phone") {
                    margin-bottom: 1.5em;
                }

                @include media(">phone", "<tablet") {
                    margin-bottom: 1.5em;
                }
            }
        }

        .presentation {
            padding: 3vw;

            @include media("<phone") {
                width: 100%;
                padding: 0;
            }

            @include media(">phone", "<tablet") {
                width: 100%;
                padding: 0;
            }

            @include media(">=768px", "<=1024px") {
                width: 100%;
                padding: 0;
            }
        }
        .trama {
            height: 100%;
            position: absolute;
            right: -2vw;
            top: 0;
            bottom: 0;
            margin: auto 0;

            @include media("<phone") {
                display: none !important;
            }

            @include media(">phone", "<tablet") {
                display: none !important;
            }

            @include media(">=768px", "<=1024px") {
                display: none !important;
            }
        }

        .video {
            padding: 3vw;
             @include media("<phone") {
                width: 100%;
                height: 60% !important;
                padding: 0;
            }

            @include media(">phone", "<tablet") {
                width: 100%;
                padding: 0;
            }

            @include media(">=768px", "<=1024px") {
                width: 100%;
                padding: 0;
            }
        }
    }

</style>