<template>
    <div 
        class=" flex-column-center center-row-start card-focus-area mg-bottom-x2 fade-in-left content-white"
        :style="'border-color: ' +  focus.color + '!important'"
    >

        <img 
            :src="returnIcon(focus.code)" 
            :alt="focus.tag" 
            class="wdt-x1" 
        />

        <div class="hgh-x6 wdt-x10">
            <span
                class="text-medium wdt-x10 no-margin text-subtitle-section"
                :class="'color-focus-' + focus.code"
            >
                {{ focus.name }}
            </span>
        </div>

        <div class="hgh-x4 wdt-x10">
            <div class="flex-row-center start-row-center">
                <span 
                    class="number-total text-title text-medium no-margin"
                    :class="'color-focus-' + focus.code"
                >
                    {{ focus.total }}
                </span>

                <span 
                    class="text-medium"
                    :class="'color-focus-' + focus.code"
                    style="margin-left: 1vw; margin-bottom: 0 !important;"
                    v-if="focus.total === 1"
                >
                    
                    Proyecto registrado
                </span>
                <span 
                    class="text-medium"
                    style="margin-left: 1vw; margin-bottom: 0 !important;"
                    :class="'color-focus-' + focus.code"
                    v-else
                >
                    Proyectos registrados
                </span>

                
            </div>
        </div>
        

    </div>
</template>

<script>
    export default {
        data: () => {
            return {

            };
        },

        props: {
            focus: {
                type: Object,
                default: () => {}
            }
        },

        methods: {
            returnIcon(code) {
                var images = require.context('@/assets/images/', false, /\.png$/);
                return images('./icon-' + code + ".png");
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/css/App.scss';

    .card-focus-area {
        width: calc((100% / 3) - 1vw);
        margin: 0 0.5vw;
        height: 8vw;
        padding: 1vw 1.4vw;
        border: solid .3em; 
        border-radius: .8em;
        position: relative;

        img {
            position: absolute;
            top: -1.5vw;
            right: 1vw;
        }
    }

    .card-focus-area.first {
        margin-right: 0;
    }

    .card-focus-area.last {
        margin-left: 0;
    }
</style>