<template>
    <div class="sidebar flex-column-center center-column-center full-size box-shadow">
        <div 
            :class="index === option_active ? 'full-width flex-row-center start-row-center menu-option active': 'full-width flex-row-center start-row-center menu-option '" 
            :key="'menu_'+index"
            v-for="(option, index) in menu"
            @click="goToOptionMenu(index)"
        >
            <font-awesome-icon 
                :icon="['fas', option.icon]" 
                class="icon icon-primary wdt-x2" 
            />
            <span class="wdt-x8 text-medium text-primary no-margin">{{ option.title }}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SideBar",
        data: () => {
            return {
                title: "sidebar",
                option_active: 0,
                menu: [
                    {
                        icon: "home",
                        title: "Inicio",
                        route: "home"
                    },
                    {
                        icon: "project-diagram",
                        title: "Proyectos",
                        route: "projects"
                    },
                    {
                        icon: "sign-out-alt",
                        title: "Salir",
                        route: "logout"
                    }

                ]
            };
        },
        methods: {
            goToOptionMenu(index ){
                this.option_active = index;
                this.$emit('changeView', index);
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/css/App.scss';

    .sidebar {

        .menu-option {
            margin-bottom: 0.4vw;
            padding: 0.8vw 1vw;
            cursor: pointer;

            transition: all 0.1s 0.1s ease-in-out;
            
            span {
                transition: all 0.1s 0.1s ease-in-out;
            }

            .icon {
                padding-right: 0.5vw;
                transition: all 0.1s 0.1s ease-in-out;
            }
        }

        .menu-option:hover {
            background: #17458f;
            transition: all 0.1s 0.1s ease-out;

            span {
                color: #fff;
                transition: all 0.1s 0.1s ease-out;
            }

            .icon {
                color: #fff;
                transition: all 0.1s 0.1s ease-out;
            }
        }

        .menu-option.active {
            border-right: solid 6px #17458f;
        }
    }
</style>