<template>
    <div class="card_sponsors">

        <div class="flex-column start-column-start">
            <span class="text-medium mg-bottom-x05 mg-top-x2 full-width"> {{ title }} </span>
            <span class="text-light mg-bottom-x2 full-width"> {{ description}} </span>
        </div>


        <div class="flex-row-center center-row-start">
            <div class="wdt-x5 pd-right-x1 full-height">

                <FormulateForm @submit="addSponsor">
                    <FormulateInput 
                        type="text"
                        name="Nombre del patrocinador"
                        label="Nombre del patrocinador *"
                        placeholder="Nombre del patrocinador"
                        validation="required"
                        v-model="form.name"
                    />

                    <FormulateInput 
                        type="url"
                        name="Sitio oficial del patrocinador"
                        label="Sitio oficial del patrocinador"
                        placeholder="Sitio oficial del patrocinador"
                        v-model="form.url"
                        help="Puede ser el sitio web oficial o una red social donde se encuentre mayor información del patrocinador"
                    />

                    <FormulateInput
                        type="submit"
                        label="Agregar"
                    />
                </FormulateForm>

            </div>
            <div class="wdt-x5 pd-left-x1 flex-column-center start-column-center full-height">
                <span class="text-medium mg-bottom-x1"> 
                    {{ subtitle }} 
                </span>

                <div class="cont-list-sponsors full-height wdt-x10 full-width">

                    <span 
                        class="text-light no-margin full-width"
                        style="display: flex; align-items: center; justify-content: center;" 
                        v-if="sponsors.length === 0"
                    >
                        {{ empty }}
                    </span>

                    <div 
                        v-for="(item, index) in sponsors"
                        :key="'sponsor_' + index"
                        class="flex-row-center start-row-center item-sponsor content-grey-d wdt-x10 mg-bottom-x1"
                        v-else
                    >
                        <span class="text-light no-margin">
                            {{ item.name }}
                        </span>

                        <font-awesome-icon 
                            :icon="['fas', 'times-circle']" 
                            class="icon icon-primary"
                            @click="removeSponsor(item.name, index)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data: () => {
            return {
                title: "Patrocinadores del proyecto",
                subtitle: "Listado de patrocinadores",
                description: "Si el proyecto cuenta con el patrocinio o apoyo de una institución, grupo o red, ingrese el nombre y la url de su sitio web o red social que la identifica como válida.",
                empty: "No hay patrocinadores registrados",

                form: {
                    name: "",
                    url: ""
                },

                listSponsors: []
            }
        },

        props: {
            sponsors: {
                type: Array,
                default:() => {}
            }
        },

        /* watch: {
            sponsors: function(sponsors) {
                this.listSponsors = sponsors;
            }
        }, */

        methods: {
            addSponsor() {
                let data = {
                    name: this.form.name,
                    ulr: this.form.url
                }
                this.$emit('newSponsor', data);
                this.form.name = "";
                this.form.url = "";
            },

            removeSponsor(name, position) { 
                let temp_list_sponsors = [];
                this.sponsors.forEach((sponsor, index) => {
                    if(sponsor.name !== name && index !== position) {
                        temp_list_sponsors.push(sponsor);
                    }
                });
                this.sponsors = temp_list_sponsors;
                this.$emit('updateSponsorList', this.sponsors);
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/css/App.scss';

    .card_sponsors {
        .cont-list-sponsors {
            overflow: scroll;
            height: 250px;
            .item-sponsor {
                justify-content: space-between;
                padding: 0.5vw 1vw;
                border-radius:.6vw;
            }
        }

    }

</style>