<template>
    <div class="form-project flex-column-center start-column-center full-size">

        <div class="flex-row-center start-row-center cont-text full-width">
            <div class="wdt-x9 flex-row-center start-row-center">
                <span class="text-primary text-title-section text-medium full-width" v-if="action === 0">
                    {{ text.title_register }}
                </span>
                <span class="text-primary text-title-section text-medium full-width" v-else>
                    {{text.title_update }}
                </span>
            </div>
            <div class="wdt-x1 flex-row-center end-row-center">
                <font-awesome-icon 
                    :icon="['fas', 'times-circle']" 
                    class="icon icon-primary icon-medium" 
                    @click="formClosed"
                />
            </div>
        </div>

        <div class="hgh-x1"></div>
        
        <FormulateForm 
            class="flex-column-center start-column-start full-width column-form"
            @submit="actionToProject"
        >
            <div class="flex-row-center start-row-start full-width mg-bottom-x2">

                <div class="wdt-x8 pd-right-x1">
                    <Loader :active="loader.active" :message="loader.text"></Loader>
                    <Messages :active="messages.active" :message="messages.text" :type="messages.type" style="margin: 0 !important"></Messages>
                </div>

                <div class="wdt-x2 pd-left-x1">
                    <FormulateInput
                        type="submit"
                        :label="action === 0 ? text.register : text.update "
                        :disabled="loader.active"
                    />
                </div>

            </div>

            <div class="flex-column-center start-column-start full-width">

                <div class="flex-column-center start-column-start wdt-x10 full-height">
                    <span class="text-medium mg-bottom-x1">Información del proyecto</span>

                    <div class="flex-row-center start-row-start wdt-x10">
                        <div class="wdt-x5 flex-column-center start-column-start pd-right-x05">
                            <FormulateInput 
                                type="text"
                                name="Título"
                                label="Título del proyecto *"
                                placeholder="Escribe aquí el título del proyecto"
                                validation="required"
                                v-model="project.data.title"
                            />

                            <FormulateInput 
                                type="textarea"
                                name="Descripción"
                                label="Descripción"
                                placeholder="Breve descripción del proyecto"
                                v-model="project.data.description"
                                @keyup="validateDecription()"
                                :help="`No exceda las 900 palabras. ${max_description} de 900`"
                            />

                            <FormulateInput
                                v-model="project.data.focus_area"
                                :options="focus_area"
                                type="select"
                                placeholder="Escoja una opción"
                                label="Escoja el área de interés *"
                                name="Área de interés"
                                validation="required"
                            />

                        </div>
                        <div class="wdt-x5 flex-column-center start-column-start pd-left-x05">
                            <FormulateInput
                                type="text"
                                name="Presupuesto"
                                label="Presupuesto del proyecto"
                                placeholder="Presupuesto del proyecto"
                                help="Esto es un presupuesto aproximado del costo del proyecto"
                                validation="required"
                                v-model="project.data.estimated_amount"
                            />
        
                            <FormulateInput
                                type="text"
                                name="Ubicación"
                                label="Ubicación del proyecto"
                                placeholder="Ubicación del proyecto"
                                help="Coloque el/los lugares donde se realizará el proyecto"
                                validation="required"
                                v-model="project.data.location"
                            />
                        </div>
                    </div>

                    <span class="text-medium mg-bottom-x05 mg-top-x2">Fotografías del proyecto</span>
                    <span class="text-light mg-bottom-x1">
                        Se recomienda que las fotografías deben ser de 400 x 400 pixeles en formato JPG o PNG y no excedan un tamaño de 2MB.
                    </span>

                    <div class="wdt-x10 mg-bottom-x2">

                        <vue-upload-multiple-image
                            @upload-success="formatedImagesList"
                            :data-images="listImagesTemp"
                        ></vue-upload-multiple-image>

                        <div 
                            class="flex-row-center start-row-center"
                            style="justify-content: space-between;"
                        >
                            <span 
                                class="content-primary text-medium text-white load-images no-margin"
                                @click="loadImagesToServer"
                            >
                                Guardar imágenes
                            </span>

                            <span 
                                class="wdt-x7 text-primary text-medium no-margin"
                                v-if="stateImage.active"
                            >
                                {{ stateImage.text }}
                            </span>
                        </div>
                    </div>

                    <span class="text-medium mg-bottom-x05 mg-top-x2">Información Complementaria</span>
                    <span class="text-light mg-bottom-x1">
                        Información relacionada al video y documentación luego de la presentación del proyecto
                    </span>

                    <div class="flex-row-center start-row-start wdt-x10">
                        <div class="wdt-x5 flex-column-center start-column-start pd-right-x05">
                            <FormulateInput 
                                type="url"
                                label="Video de presentación"
                                placeholder="URL del video de presentación"
                                v-model="project.data.video"
                                help="Ve al canal de Rotary en Youtube luego haz clic derecho en el video y selecciona copiar el código de inserción"
                            />
        
                            <FormulateInput 
                                type="url"
                                label="Resumen del proyecto (opcional)"
                                placeholder="Resumen informativo del proyecto"
                                v-model="project.data.document"
                                help="Este resumen permitirá a clubes y patrocinadores descargarlo desde el catálogo de proyectos."
                            />
                        </div>
                    
                        <div class="wdt-x5 flex-column-center start-column-start pd-left-x05">
                            <FormulateInput 
                                type="url"
                                label="Enlace del repositorio (opcional)"
                                name="Repositorio"
                                placeholder="Repositorio del proyecto"
                                v-model="project.data.link"
                                help="Enlace donde contiene toda la documentación del proyecto"
                            />
        
                            <FormulateInput 
                                type="url"
                                label="Enlace de interés (opcional)"
                                placeholder="Información adicional del proyecto"
                                v-model="project.data.focus_link"
                                help="Enlace externo donde se pueda conocer más sobre el proyecto"
                            />
                        </div>
                        



                    </div>
                </div>

                <div class="flex-column-center start-column-start wdt-x10 full-height">
                    <span class="text-medium mg-bottom-x1 mg-top-x2">Información del club</span>
                    <div class="flex-row-center start-row-start wdt-x10">
                        <div class="wdt-x5 flex-column-center start-column-start pd-right-x05">

                            <FormulateInput 
                                type="text"
                                name="Nombre del club"
                                label="Nombre del Club *"
                                placeholder="Nombre del Club Rotary"
                                validation="required"
                                v-model="project.data.club.name"
                            />


                            <FormulateInput 
                                type="text"
                                name="Ciudad del club"
                                label="Ciudad *"
                                placeholder="Ciudad del Club Rotary"
                                validation="required"
                                v-model="project.data.club.city"
                            />

                            <FormulateInput 
                                type="text"
                                name="Parroquia del club"
                                label="Parroquia *"
                                placeholder="Parroquia del Club Rotary"
                                validation="required"
                                v-model="project.data.club.parish"
                            />

                        </div>
                        <div class="wdt-x5 flex-column-center start-column-start pd-left-x05">

                            <FormulateInput 
                                type="text"
                                name="Cantón del club"
                                label="Cantón *"
                                placeholder="Cantón del Club Rotary"
                                validation="required"
                                v-model="project.data.club.canton"
                            />

                            <FormulateInput 
                                type="text"
                                name="Provincia del club"
                                label="Provincia *"
                                placeholder="Provincia del Club Rotary"
                                validation="required"
                                v-model="project.data.club.province"
                            />

                        </div>

                    </div>
                    <span class="text-medium mg-top-x2 mg-bottom-x05 mg-top-x2">Información de personal de contacto</span>
                    <span class="text-light mg-bottom-x1">Información relacionada a la persona con la que pueden comunicarse</span>

                    <div class="flex-column-center start-column-start wdt-x10">

                        <div class="flex-row-center start-row-start wdt-x10 mg-bottom-x1">
                            <div class="wdt-x5 flex-column-center start-column-start pd-right-x05">
                                <FormulateInput 
                                    type="text"
                                    label="Nombres completos *"
                                    placeholder="Nombres completos"
                                    name="Nombres"
                                    validation="required"
                                    v-model="project.data.contact.names"
                                />

                                <FormulateInput 
                                    type="text"
                                    label="Número de teléfono *"
                                    placeholder="ej. 593987654321"
                                    name="Teléfono"
                                    help="Evite espacios y no ingrese el signo +"
                                    validation="required"
                                    v-model="project.data.contact.phone"
                                />

                            </div>
                            <div class="wdt-x5 flex-column-center start-column-start pd-left-x05">
                                <FormulateInput 
                                    type="text"
                                    label="Apellidos completos *"
                                    placeholder="Apellidos completos"
                                    name="Apellidos"
                                    validation="required"
                                    v-model="project.data.contact.surnames"
                                />
                                <FormulateInput 
                                    type="email"
                                    label="Correo electrónico"
                                    placeholder="ej. tucorreo@ejemplo.com"
                                    name="Correo electrónico"
                                    validation="required|email"
                                    v-model="project.data.contact.email"
                                />
                                
                            </div>
                        </div>

                        <FormulateInput 
                            type="textarea"
                            name="Actividades"
                            label="Actividades que realiza"
                            placeholder="Breve descripción de las actividades o cargo que desempeña en el proyecto"
                            v-model="project.data.contact.activities"
                            :help="`No exceda las 200 palabras. ${project.data.description.length} de 200`"
                            validation="max:200,length"
                        />

                        <CardSponsors
                            :sponsors="project.data.sponsors"
                            @newSponsor="(dataSponsor) => newSponsor(dataSponsor)"
                            @updateSponsorList="(list) => updateSponsorList(list) "
                        ></CardSponsors>
                    </div>
                </div>
            </div>
        </FormulateForm>

    </div>
</template>

<script>
import Loader from "@/utils/Loader.vue";
import Messages from "@/utils/Messages.vue";
import CardSponsors from "@/utils/CardSponsors.vue";

import { list_focuses_area } from "@/assets/resources/formats_data.js";
import { mapActions, mapGetters } from "vuex";
import { timestamp } from "@/config/firebase/app.js";

import VueUploadMultipleImage from 'vue-upload-multiple-image'


export default {
    name: "FormProject",
    data: () => {
        return {
            focus_area: {},
            listImagesTemp: [],
            lista: [],
            stateImage: {
                active: false,
                text: ""
            },
            max_description: 0,
            project: {
                id: "",
                data: {
                    date_register: "",
                    title: "",
                    description: "",
                    focus_area: {},
                    location: "",
                    link: "",
                    focus_link: "",
                    estimated_amount: "",
                    video: "",
                    document: "",
                    images: [],
                    sponsors: [],
                    club: {
                        name: "",
                        city: "",
                        parish: "",
                        canton: "",
                        province: ""
                    },
                    contact: {
                        names: "",
                        surnames: "",
                        activities: "",
                        phone: "",
                        email: ""
                    },
                    active: true,
                    registered_by: ""
                }
            },

            text: {
                title_register: "Registrar nuevo proyecto",
                title_update: "Actualizar información de proyecto",
                register: "REGISTRAR PROYECTO",
                update: "ACTUALIZAR PROYECTO"
            },

            loader: {
                active: false,
                text: "Ingresando..."
            },
            messages: {
                active: false,
                text: "",
                type: 0
            }
        };
    },
    beforeMount(){
        this.initData();
    },

    props: {
        action: {
            type: Number,
            default: 0
        },
        data: {
            type: Object,
            default: () => {}
        }
    },

    components: {
        Loader,
        Messages,
        CardSponsors,
        VueUploadMultipleImage
    },

    computed: {
        ...mapGetters('users', {
            user: "data_user"
        })
    },

    methods: {

        ...mapActions('projects', {
            updateProject: "updateProject",
            registerProject: "registerProject",
            uploadImages: "uploadImages"
        }),

        initData() {
            let list = list_focuses_area();
            let aux = [];
            list.forEach((item, index) => {
                aux.push({
                    label: item.name,
                    value: item,
                    id: "item_" + index
                }); 
            });
            this.focus_area = aux;
            if (this.action === 1) this.project = this.data;

            this.validateDecription();
        },

        formClosed() {
            this.$emit('formClosed');
        },

        actionToProject() {
            if(this.action === 0) {
                this.saveProject();
            } else {
                this.editProject();
            }
        },

        setMessage(isActive, message, type) {
            this.messages.active = isActive;
            this.messages.text = message;
            this.messages.type = type;
        },

        async saveProject() {
            this.loader.active = true;
            this.messages.active = false;
            this.loader.text = "Guardando, por favor espere..."

            this.project.data.date_register = timestamp.now();
            this.project.data.registered_by = this.user.id;

            let response =  await this.registerProject(this.project.data);

            this.setMessage(true, response.message, response.code);
            this.loader.active = false;
            if(response.code === 0) this.cleanForm();
        },

        async editProject() {
            this.loader.active = true;
            this.messages.active = false;
            this.loader.text = "Actualizando, por favor espere..."
            let response = await this.updateProject(this.project);
            this.loader.active = false;
            this.setMessage(true, response.message, response.code);
        },

        cleanForm() {
            this.project = {
                id: "",
                data: {
                    date_register: "",
                    title: "",
                    description: "",
                    focus_area: {},
                    link: "",
                    focus_link: "",
                    estimated_amount: "",
                    video: "",
                    document: "",
                    images: [],
                    sponsors: [],
                    club: {
                        name: "",
                        city: "",
                        parish: "",
                        canton: "",
                        province: ""
                    },
                    contact: {
                        names: "",
                        surnames: "",
                        activities: "",
                        phone: "",
                        email: ""
                    },
                    active: true,
                    registered_by: ""
                }
            }
        },

        updateSponsorList(list) {
            this.project.data.sponsors = list;
        },

        newSponsor(sponsor) {
            console.log(this.project.data.sponsors);
            this.project.data.sponsors.push(sponsor);
        },

        formatedImagesList(formData, index, fileList) {

            this.lista.push({
                name: fileList[index].name,
                path: fileList[index].path
            });
        },

        async loadImagesToServer() {
            if ( this.lista.length !== 0 ) {

                this.setStateImages(true, "Guardando imagenes...");

                let data = {
                    list: this.lista,
                    titleProject: this.project.data.title
                };

                let response = await this.uploadImages(data);
                
                if (response.code === 0) {
                    this.project.data.images = response.urlsImages;
                    this.setStateImages(true, "Imágenes guardadas correctamente");
                } else {
                    this.setStateImages(true, "Problemas al guardar las imágenes");
                }
                
                setTimeout(() => {
                    this.setStateImages(false, "");
                }, 2000);


            } else {
                this.setStateImages(true,"No hay imágenes cargadas");
                setTimeout(() => {
                    this.setStateImages(false, "");
                }, 2000);
            }
        },


        setStateImages(state, text) {
            this.stateImage.active = state;
            this.stateImage.text = text;

        },

        async validateDecription() {
            
            await this.formatDescription();

            let description = this.project.data.description.split(" ");
            this.max_description = description.length;
        },


        formatDescription() {
            let firstWhite = /^ /
            let lastWhite = / $/
            let anyWhites = /[ ]+/g
            let description = this.project.data.description;

            description = description.replace(anyWhites, " ");
            description = description.replace(firstWhite, "");
            description = description.replace(lastWhite, "");
        }
    },
}
</script>


<style lang="scss" scoped>
    @import '@/assets/css/App.scss';

    .cont-text {
        justify-content: space-between;
    }

    .column-form {
        overflow: scroll;
    }

    .load-images {
        border-radius: .4em;
        padding: 0.5vw 1vw;
        cursor: pointer;
    }

    .load-images:active {
        transform: scale(0.96);
    }

    
</style>