<template>
    <div class="summary flex-column-center start-column-start">

        <span class="text-medium text-primary text-subtitle-section">
            {{ `👋 Hola, ${user.data.names}` }}
        </span>

        <span class="text-medium text-primary text-title wdt-x5">Resumen de proyectos</span>

        <span class="text-light text-primary text-subtitle-section mg-bottom-x3">
            {{ `Aquí tienes el resumen general de proyectos. Hay ${total} proyectos registrados` }}
        </span>

        <div class="cont-focus-cards flex-row-center start-row-start wdt-x10">

            <CardFocusArea
                v-for="(focus, index) in focuses"
                :key="focus.code"
                :class="{
                    'first': index === 0,
                    'last': index === focuses.length - 1 || (index % 3) !== 0
                }"
                :focus="focus"
            ></CardFocusArea>

        </div>
    </div>
</template>

<script>

import { mapGetters, mapActions }   from "vuex";
import { list_focuses_area }        from "@/assets/resources/formats_data.js";
import CardFocusArea                from "@/utils/CardFocusArea.vue";

export default {
    name: "Summary",
    data: () => {
        return {
            title: "summary",
            focuses: [],
            total: 0
        };
    },

    beforeMount() {
        this.initialData();
    },

    computed: {
        ...mapGetters('projects', {
            countProjects: "countProjects"
        }),

        ...mapGetters('users', {
            user: "data_user"
        })

    },

    components: {
        CardFocusArea
    },

    methods: {
        ...mapActions('projects', {
            getTotalProjects: "getTotalProjects"
        }),

        async initialData() {
            await this.getTotalProjects();

            let list = list_focuses_area();
            list.forEach((item, index) => {
                item.total = this.countProjects[index].total;
                this.total += this.countProjects[index].total;
                this.focuses.push(item);
            });
        },
    },
}
</script>

<style lang="scss" scoped>
    @import '@/assets/css/App.scss';

    .summary {
        position: relative;
        height: 100%;
        padding: 2vw;
        background: #f9f9f9;

        .cont-focus-cards {
            flex-flow: row wrap;
            justify-content: space-between;
        }
    }
</style>