<template>
    <div class="login full-width flex-column-center center-column-center" id="login">
        <div class="main-content flex-row-center center-row-center full-size">
            <div class="middle-left full-height flex-column-center center-column-start">
                <div class="container-image flex-column-center center-column-start">
                    <img src="@/assets/images/logo.png" alt="logo-rotary-ecuador" class="logo full-size" />
                </div>
                <div class="hgh-x05"></div>
                <div class="container-text full-width">
                    <span class="form-title text-primary text-medium full-width">Iniciar Sesión</span>
                    <span class="form-content text-grey text-light full-width">Ingresa con las credenciales enviadas al correo</span>
                </div>

                <div class="hgh-x05"></div>

                <FormulateForm
                    class="wdt-x10"
                    @submit="login"
                >
                    <div class="label">
                        <font-awesome-icon 
                            :icon="['fas', 'user']" 
                            class="icon icon-primary" 
                        />
                        <span class="text-medium text-primary">Correo electrónico</span>
                    </div>
                    <FormulateInput 
                        type="email"
                        placeholder="ej. tucorreo@ejemplo.com"
                        name="Correo electrónico"
                        validation="required|email"
                        v-model="form.email"
                    />
                    
                    <div class="label">
                        <font-awesome-icon 
                            :icon="['fas', 'lock']" 
                            class="icon icon-primary" 
                        />
                        <span class="text-medium text-primary">Contraseña</span>
                    </div>

                    <FormulateInput 
                        type="password"
                        name="Password"
                        placeholder="xxxxxxxx"
                        validation="required"
                        v-model="form.password"
                    />

                    <FormulateInput
                        type="submit"
                        label="INGRESAR"
                    />
                    
                </FormulateForm>

                <Loader :active="loader.active" :message="loader.text"></Loader>

                <Messages :active="messages.active" :message="messages.text" :type="messages.type"></Messages>

                <!-- <div class="flex-row-center center-row-center hgh-x05 wdt-x10 cont-link">
                    <div class="hgh-x05 wdt-x08 content-yellow"></div>
                        <span class="text-medium text-yellow link">
                            Olvidé mi contraseña
                        </span>
                    <div class="hgh-x05 wdt-x08 content-yellow"></div>
                </div> -->

            </div>

            <div class="middle-right content-secondary full-height flex-column-center center-column-center">
                <div class="label copyright">
                    <font-awesome-icon 
                        :icon="['fas', 'copyright']" 
                        class="icon icon-yellow" 
                    />
                    <span class="text-light text-yellow">TODOS LOS DERECHOS RESERVADOS - ROTARY ECUADOR</span>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import { auth } from "@/config/firebase/auth";
    import { mapGetters, mapActions } from 'vuex';
    import Loader from "@/utils/Loader.vue";
    import Messages from "@/utils/Messages.vue";

    export default {
        name: 'Login',

        data: () => {
            return {
                form: {
                    email: null,
                    password: null
                },
                loader: {
                    active: false,
                    text: "Ingresando..."
                },
                messages: {
                    active: false,
                    text: "",
                    type: 0
                }
            };
        },
        beforeMount () {
            if(this.user.status) this.$router.replace("/dashboard");
        },
        components: {
            Loader,
            Messages
        },
        computed: {
            ...mapGetters('users', {
                user: "data_user"
            })
        },
        methods: {

            ...mapActions('users', {
                signin_store: 'signIn'
            }),

            async login() {
                this.loader.active = true;
                this.messages.active = false;
                
                let response = await this.signin_store(this.form);

                if (response.code === 0) {
                    this.loader.active = false;
					this.$router.replace("/dashboard");
                } else {
                    this.setMessage(true, response.message, response.code);
                    this.loader.active = false;
                }
            },

            setMessage(isActive, message, type) {
                this.messages.active = isActive;
                this.messages.text = message;
                this.messages.type = type;
            }
        },
    }
</script>


<style lang="scss" scoped>
	@import '@/assets/css/App.scss';

    #login {
        height: 100vh;

        .main-content {
            .middle-left {
                padding: 0 8vw;

                .container-image {
                    width: 50%;
                    height: auto;
                }

                .container-text {
                    .form-title {
                        margin-bottom: 0.4vw;
                    }
                }

                .cont-link {
                    cursor: pointer;
                    transition: all 0.15s 0.15s;

                    .link { margin: 0 0.5vw; }
    
                }
                .cont-link:hover {
                    transform: scale(1.05);
                    transition: all 0.15s 0.15s;
                }
            }

            .middle-right {

                .label.copyright {
                    width: auto;
                    position: absolute;
                    bottom: 2vw;
                    right: 4vw;
                    font-size: 0.7vw;
                }
            }
        }
    }


</style>