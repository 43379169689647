<template>
    <div class="project-view flex-column-center start-column-center">
        <Navbar
            @goToSection="(index) => goToSection(index)"
            :active="3"
        ></Navbar>
        
        <Loader 
            :active="loader.active" 
            :message="loader.text"
        ></Loader>
        
        <Messages 
            :active="messages.active" 
            :message="messages.text" 
            :type="messages.type"
        ></Messages>

        <div class="cont-info-project flex-column-center center-column-start wdt-x10">

            <Modal 
                :active="show"
                :code="code"
                @changeStateModal="changeStateModal"
            ></Modal>

            <div class="flex-row-center start-row-center cont-options-project wdt-x10 mg-bottom-x2">
                <div class="wdt-x8 cont-title-section">
                    <span 
                        class="wdt-x10 text-title text-medium no-margin"
                        :class="'color-focus-' + code"
                    >
                        {{project.data.focus_area.name}}
                    </span>
                </div>

                <div class="wdt-x2 flex-row-center end-row-center options-project">
                    <button 
                        
                        class="text-medium content-white text-button"
                        :class="'color-focus-' + code"
                        @click="$router.go(-1)"
                    >
                        Regresar
                    </button>

                    <button 
                        class="text-white mg-left-x1 text-medium text-button"
                        :class="'background-focus-' + code"
                        @click="changeStateModal"
                    >   
                        <font-awesome-icon 
                            :icon="['fas', 'share-alt']" 
                            class="icon icon-white icon-medium"
                        />
                        Compartir
                    </button>
                </div>
            </div>


            <span 
                class="text-subtitle text-white text-medium wdt-x10 text-medium pd-left-x1 pd-bottom-x05 pd-top-x05 mg-bottom-x2 title-section-project"
                :class="'background-focus-' + code"
            >
                {{ title_a }}
            </span>

            <div class="wdt-x10 flex-row-center center-row-start pd-bottom-x2 change-to-column">

                <Description :project="project.data" ></Description>
                <Aditional :project="project.data" ></Aditional>

            </div>

            <span 
                class="text-subtitle text-white text-medium wdt-x10 text-medium pd-left-x1 pd-bottom-x05 pd-top-x05 mg-bottom-x2 mg-top-x2 title-section-project"
                :class="'background-focus-' + code"
            >
                {{ title_b }}
            </span>

            <Sponsors :sponsors="project.data.sponsors" ></Sponsors>

            <span 
                class="text-subtitle text-white wdt-x10 text-medium pd-left-x1 pd-bottom-x05 pd-top-x05 mg-bottom-x2 mg-top-x2 title-section-project"
                :class="'background-focus-' + code"
            >
                {{ title_c }}
            </span>

            <div class="wdt-x10 flex-row-center center-row-start change-to-column">

                <Club :club="project.data.club" ></Club>
                <Contact :contact="project.data.contact" ></Contact>

            </div>

            <!-- <span 
                class="text-subtitle text-white wdt-x10 text-medium pd-left-x1 pd-bottom-x05 pd-top-x05 mg-bottom-x1 mg-top-x2 title-section-project"
                :class="'background-focus-' + code"
            >
                {{ title_c }}
            </span> -->

            <!-- <Related></Related> -->

        </div>

        <Copyright></Copyright>

    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import Loader       from "@/utils/Loader.vue";
import Messages     from "@/utils/Messages.vue";
import Modal        from "@/utils/Modal.vue";  

import Navbar       from "@/components/Navbar.vue";
import Description  from "@/components/project/Description.vue";
import Aditional    from "@/components/project/Aditional.vue";
import Sponsors     from "@/components/project/Sponsors.vue";
import Club         from "@/components/project/Club.vue";
import Contact      from "@/components/project/Contact.vue";
/* import Related      from "@/components/project/Related.vue"; */
import Copyright    from "@/components/landing/Copyright.vue";

    export default {
        data: () => {
            return {
                loader: {
                    active: true,
                    text: ""
                },
                messages: {
                    active: false,
                    text: "",
                    type: 0
                },
                title_a: "Información del proyecto",
                title_b: "Patrocinadores del proyecto",
                title_c: "Generadores del proyecto",

                code: "",
                show: false
            };
        },

        created() {
            this.initialData();
        },

        computed: {
            ...mapGetters('projects', {
                project: "getProject"
            })
        },

        methods: {
            ...mapActions('projects', {
                getProjectByID: "getProjectByID"
            }),

            async initialData() {
                let id_project = this.$route.params.id_project;
                this.code = this.$route.params.focus_code;

                this.loader.active      = true;
                this.messages.active    = false;
                this.loader.text        = "Obteniendo proyecto..."

                let response = await this.getProjectByID(id_project);

                if (response.code !== 0) {
                    this.setMessage(true, response.message, response.code);   
                }

                this.loader.active      = false;
            },

            setMessage(isActive, message, type) {
                this.messages.active    = isActive;
                this.messages.text      = message;
                this.messages.type      = type;
            },

            goToSection(index) {
                this.$router.push({
                    name: "Home"
                })
            },

            changeStateModal() {
                this.show = !this.show;
            }
        },

        components: {
            Modal,
            Navbar,
            Loader,
            Messages,
            Description,
            Aditional,
            Sponsors,
            Club,
            Contact,
            //Related,
            Copyright
        }
    }
</script>


<style lang="scss" scoped>

    @import '@/assets/css/App.scss';


    .project-view {
        position: relative;
        background: #e1ebf2;

        .cont-info-project {
            padding:  10vw 4vw 4vw 4vw;

            @include media("<phone") {
                padding: 16vw 4vw 4vw 4vw;
            }

            @include media(">phone", "<tablet") {
                padding: 20vw 4vw 4vw 4vw;
            }

            @include media(">=768px", "<=1024px") {
                padding: 12vw 4vw 4vw 4vw;
            }

            .cont-options-project {
                @include media("<phone") {
                    display: flex !important;
                    flex-flow: column !important;
                    align-items: start !important;
                    justify-content: start !important;
                }
                @include media(">phone", "<tablet") {
                    display: flex !important;
                    flex-flow: column !important;
                    align-items: start !important;
                    justify-content: start !important;
                }

                @include media(">=768px", "<=1024px") {
                    display: flex !important;
                    flex-flow: column !important;
                    align-items: start !important;
                    justify-content: start !important;
                }

                .cont-title-section {
                    @include media("<phone") {
                        width: 100% !important;
                        margin-bottom: 4vw;
                    }
                    @include media(">phone", "<tablet") {
                        width: 100% !important;
                        margin-bottom: 4vw;
                    }

                    @include media(">=768px", "<=1024px") {
                        width: 100% !important;
                        margin-bottom: 4vw;
                    }
                }

                .options-project {
                    @include media("<phone") {
                        width: 100% !important;
                        margin-bottom: 4vw;
                    }
                    @include media(">phone", "<tablet") {
                        width: 100% !important;
                        margin-bottom: 4vw;
                    }

                    @include media(">=768px", "<=1024px") {
                        width: 100% !important;
                        margin-bottom: 4vw;
                    }
                }
            }

            .title-section-project {
                border-radius: .4em;

                @include media("<phone") {
                    padding: 2vw ;
                    border-radius: .4em;
                }
                @include media(">phone", "<tablet") {
                    padding: 2vw ;
                    border-radius: .4em;
                }

                @include media(">=768px", "<=1024px") {
                    padding: 2vw ;
                    border-radius: .4em;
                }
            }

            .change-to-column {
                @include media("<phone") {
                    display: flex !important;
                    flex-flow: column !important;
                    align-items: start !important;
                    justify-content: start !important;
                }

                @include media(">phone", "<tablet") {
                    display: flex !important;
                    flex-flow: column !important;
                    align-items: start !important;
                    justify-content: start !important;
                }

                @include media(">=768px", "<=1024px") {
                    display: flex !important;
                    flex-flow: column !important;
                    align-items: start !important;
                    justify-content: start !important;
                }
            }
        }

    }   
    
</style>