<template>
    <div class="wdt-x10 cont-focuses-area flex-row-center header">
            <div class="fade-in-left wdt-x10 hgh-x2 focus-info flex-row-center center-row-start">
                
                <img 
                    :src="returnIcon(focus.code)" 
                    :alt="focus" 
                    class="back-icon-trama" 
                />

                <div class="wdt-x5 info-focus-left flex-column-center center-column-start hgh-x10 fade-in-left">
                    
                    <span 
                        :style="'color: ' + focus.color"
                        class="text-medium text-title mg-bottom-x2"
                    >
                        {{ focus.name }}
                    </span>

                    <span class="text-light text-subtitle-section">
                        {{ focus.description }}
                    </span>
                </div>
                <div class="wdt-x5 info-focus-right flex-row-center center-row-center hgh-x10 fade-in-left">
                    <div class="wdt-x5 flex-column-center center-column-center hgh-x10">

                        <div class="flex-column-center center-column-center hgh-x9">
                            <img 
                                :src="returnIcon(focus.code)" 
                                :alt="focus.tag" 
                                class="full-size" 
                            />
                        </div>
                        
                    </div>
                    <div class="wdt-x5 flex-column-center center-column-start hgh-x10">

                        <span 
                            :style="'color: ' + focus.color"
                            class="text-number text-medium no-margin"
                            translate="no"
                        >
                            {{ total }}
                        </span>

                        <span
                            :style="'color: ' + focus.color"
                            class="text-title text-medium"
                            v-if="total === 1"
                        >
                            proyecto
                        </span>

                        <span
                            :style="'color: ' + focus.color"
                            class="text-title text-medium"
                            v-else
                        >
                            proyectos
                        </span>

                    </div>
                </div>
            </div>
        </div>
</template>

<script>
    export default {
        data: () => {
            return {
                
            }
        },

        props: {
            focus: {
                type: Object,
                default: () => {
                    return {};
                }
            },

            total: {
                type: Number,
                default:0
            }
        },

        methods: {
             returnIcon(code) {
                var images = require.context('@/assets/images/', false, /\.png$/);
                return images('./icon-' + code + ".png");
            }
        }
    }
</script>

<style lang="scss" scoped>
    
    @import '@/assets/css/App.scss';


        .cont-focuses-area {
            padding: 8vw 8vw 4vw 8vw;
            background: #e1ebf2;
            flex-flow: row wrap;
            justify-content: space-evenly;
            align-items: center;

            @include media("<phone") {
                padding: 12vw 4vw 4vw 4vw;
            }

            @include media(">phone", "<tablet") {
                padding: 18vw 4vw 4vw 4vw;
            }

            @include media(">=768px", "<=1024px") {
                padding: 10vw 8vw 4vw 8vw;   
            }

            @include media(">tablet", "<desktop") {
                    padding: 8vw 8vw 2vw 8vw;
            }
            
            .focus-info {
                padding: 4vw 2vw;
                height: 24vw;
                position: relative;

                @include media("<phone") {
                    padding: 6vw 4vw;
                    height: auto;
                    display: flex !important;
                    flex-flow: column !important;
                    align-items: center !important;
                    justify-content: center !important;
                }

                @include media(">phone", "<tablet") {
                    padding: 6vw 4vw;
                    height: auto;
                    display: flex !important;
                    flex-flow: column !important;
                    align-items: center !important;
                    justify-content: center !important;
                }

                @include media(">=768px", "<=1024px") {
                    
                    height: auto;
                    display: flex !important;
                    flex-flow: column !important;
                    align-items: center !important;
                    justify-content: center !important;
                }

                @include media(">tablet", "<desktop") {
                    height: auto;   
                }

                .info-focus-left { 
                    @include media("<phone") {
                        width: 100% !important;
                        margin-bottom: 2vw;
                    }

                    @include media(">phone", "<tablet") {
                        width: 100% !important;
                        margin-bottom: 4vw;
                    }

                    @include media(">=768px", "<=1024px") {
                        width: 100% !important;
                        margin-bottom: 3vw;
                    }
                }

                .info-focus-right { 
                    @include media("<phone") {
                        width: 100% !important;
                    }

                    @include media(">phone", "<tablet") {
                        width: 100% !important;
                    }

                    @include media(">=768px", "<=1024px") {
                        width: 100% !important;
                    }

                    img {
                        @include media("<phone") {
                            width: 60% !important;
                            height: 60% !important;
                        }

                        @include media(">phone", "<tablet") {
                            width: 60% !important;
                            height: 60% !important;
                        }
                    }
                }

                .back-icon-trama {
                    position: absolute;
                    left: -20vw;
                    bottom: -30vw;
                    width: 50%;
                    opacity: 0.05;
                    filter: grayscale(100);

                }

                .info-focus-left {
                    padding-right: 2vw;
                }
            }
        }


</style>