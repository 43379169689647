<template>
    <div class="preview full-width flex-column-center center-column-center"  id="preview">


        <div class="main-content flex-column-center center-column-center">

            <div class="content-primary">
                <span class="text-white text-medium text-title no-margin">
                    {{ coming_soon }}
                </span>
            </div>
            
            <img src="@/assets/images/logo.png" alt="logo-rotary-ecuador" class="logo" />

            <span class="text-yellow text-bold text-title">
                {{title}}
            </span>
            
            <span class="text-yellow text-medium text-subtitle">
                {{subtitle}}
            </span>

            <span class="text-yellow text-medium text-title-section no-margin">
                {{year}}
            </span>
        </div>
    </div>
</template>
<script>
    export default {
        name: "Preview",
        data: () => {
            return {
                title: "FERIA DE PROYECTOS",
                subtitle: "ROTARY ECUADOR",
                year: "2021 - 2022",
                coming_soon: "Próximamente"
            };
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/css/App.scss';

    #preview {
        overflow: hidden;
        height: 100vh;
        position: relative;
        background-image: url('~@/assets/images/trama.png');
        background-position: inherit;
        background-size: cover;
        background-repeat: no-repeat;

        /* @include desktop {
            background-position: center center;
        } */

        .content-primary {
            width: fit-content;
            padding: 10px 20px;
            border-radius: 6px;
            
            span.coming {
                font-size: 2vw;
            }
        }

        .main-content {
            height: 50%;
            width: 80%;

            span.subtitle {
                margin: 0.8vw 0;
            }

            .logo {
                max-width: 90%;
            }
        }
    }
</style>>
