
<template>
    <div class="sponsors wdt-x10 mg-top-x3 mg-bottom-x3 flex-column-center start-column-start">
            
        <Messages 
            v-if="sponsors.length === 0"
            :active="messages.active" 
            :message="messages.text" 
            :type="messages.type"
            class="no-margin"
        ></Messages>

        <div 
            class="wdt-x10 cont-sponsors flex-row-center start-row-center"
            v-else
        >
            <div 
                v-for="(sponsor, index) in sponsors"
                :key="'sponsor_' + index"
                class="sponsor flex-column-center center-column-center content-grey-d hgh-x10"
                :class="{
                    'first': index === 0,
                    'last': index === sponsors.length - 1 || (index % 4) !== 0
                }"
            >
                <font-awesome-icon 
                    :icon="['fas', 'hands-helping']" 
                    class="icon icon-grey-dd icon-xx-large hgh-x8"
                    style="cursor: auto"
                />
                
                <span class="text-medium text-primary hgh-x2">
                    {{ sponsor.name }}
                </span>

            </div>

        </div>
    </div>
</template>

<script>
import Messages     from "@/utils/Messages.vue";

	export default {
		data: () => {
			return {
                messages: {
                    active: true,
                    text: "No se han registrado patrocinadores",
                    type: 1
                }
            };
        },
        
        components: { Messages },

		props: {
            sponsors: {
                type: Array,
                default: () => {
                    return [];
                }
            }
        },

        methods: {
            openURL(url) {
                if (url !== '') {
                    window.open(url, "_blank");
                }
            }
        }
	};
</script>

<style lang="scss" scoped>
	@import "@/assets/css/App.scss";

	.sponsors {
        .cont-sponsors {
            height: 10vw;
            overflow-x: scroll;

            .sponsor {
                width: calc((100% / 4) - 1vw);
                margin: 0 0.5vw;
                padding: 0.5vw 1vw;
            }

            .sponsor.first {
                margin-left: 0;
            }

            .sponsor.last {
                margin-right: 0;
            }
        }
	}
</style>