<template>
    <div class="club wdt-x5 pd-right-x05 pd-top-x05 pd-bottom-x05 rsp-complete">

        <span class="text-medium text-subtitle-section mg-bottom-x2">
            Información del Club Rotary
        </span>

        <div class="wdt-x10 flex-row-center start-row-start change-to-column">
            <div class="wdt-x5 rsp-complete pd-right-x2 info-left">                
                <div class="mg-bottom-x05">
                    <span class="wdt-x10 text-medium text-grey text-subtitle-section">Nombre del Club</span>
                    <span class="wdt-x10 text-light text-subtitle-section">
                        {{ club.name }}
                    </span>
                </div>

                <div class="mg-bottom-x05">
                    <span class="wdt-x10 text-medium text-grey text-subtitle-section">Ciudad</span>
                    <span class="wdt-x10 text-light text-subtitle-section">
                        {{ club.city }}
                    </span>
                </div>
                <div class="mg-bottom-x05">
                    <span class="wdt-x10 text-medium text-grey text-subtitle-section">Parroquia</span>
                    <span class="wdt-x10 text-light text-subtitle-section">
                        {{ club.parish }}
                    </span>
                </div>
            </div>

            <div class="wdt-x5 rsp-complete pd-left-x2 info-right">
                <div class="mg-bottom-x05">
                    <span class="wdt-x10 text-medium text-grey text-subtitle-section">Cantón</span>
                    <span class="wdt-x10 text-light text-subtitle-section">
                        {{ club.canton }}
                    </span>
                </div>
                <div class="mg-bottom-x05">
                    <span class="wdt-x10 text-medium text-grey text-subtitle-section">Provincia</span>
                    <span class="wdt-x10 text-light text-subtitle-section">
                        {{ club.province }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data: () => {
            return { };
        },

        props: {
            club: {
                type: Object,
                default: () => {
                    return {};
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "@/assets/css/App.scss";
    .club {
        @include media("<phone") {
            margin-bottom: 4vw;
        }
        @include media(">phone", "<tablet") {
            margin-bottom: 4vw;
        }
        @include media(">=768px", "<=1024px") {
            margin-bottom: 4vw;
        }
        .info-right {
            @include media("<phone") {
                padding: 0 !important;
            }
    
            @include media(">phone", "<tablet") {
                padding: 0 !important;
            }

            @include media(">=768px", "<=1024px") {
                padding: 0 !important;
            }
        }

    }
</style>