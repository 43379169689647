<template>
    <div class="copyright flex-row-center center-row-center pd-top-x2 pd-bottom-x2 content-primary full-width">
        <!-- <font-awesome-icon 
            :icon="['fas', 'copyright']" 
            class="icon icon-white icon-medium mg-right-x1" 
        /> -->
        <span class="text-light text-content text-white no-margin">{{ text }}</span>
    </div>
</template>

<script>
    export default {
        data:() => {
            return {
                text: "2024 TODOS LOS DERECHOS RESERVADO – ROTARY ECUADOR"
            };
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/css/App.scss';

    .copyright {
        @include media("<phone") {
            padding: 4vw 0 !important;
        }

        @include media(">phone", "<tablet") {
            padding: 4vw 0 !important;
        }
                        
        @include media(">=768px", "<=1024px") {
            padding: 4vw 0 !important;
        }
    }

    .text-content {
        @include media("<phone") {
            font-size: .5em !important;
        }

        @include media(">phone", "<tablet") {
            font-size: .55em !important;
        }
                        
        @include media(">=768px", "<=1024px") {
            
        }
    }


</style>