export default {

    SET_DATA_USER(state, data_user) {
        state.status    = data_user.status;
        state.id        = data_user.id;
        state.data      = data_user.data;
    },

    RESTORE_DATA_USER(state) {
        state.status    = false;
        state.id        = null;
        state.data      = null;
    }
}