import { db } from "@/config/firebase/db";
import { orderListByHour } from "@/assets/resources/formats_data.js"

export const loadEventsFromDB = async ({ commit }) => {
    try {
        
        let response = await db.collection("calendar").orderBy("date", "asc").get();
    
        if(!response.empty) {

            let list_events = [];

            response.docs.forEach(doc => {
                let aux_projects = [];
                doc.data().projects.forEach(reference_project => {
                    reference_project.project.get().then(project => {
                        if(project.data().active) {
                            aux_projects.push({
                                id: project.id,
                                hour: reference_project.hour,
                                info: project.data()
                            });
                        }
                    });
                });

                list_events.push({
                    id  : doc.id,
                    data: {
                        date: doc.data().date,
                        projects:  aux_projects
                    }
                });

            });

           commit('SET_EVENTS_IN_LIST', list_events);

            return {
                code: 0,
                message: "Listado de eventos"
            }
        } else {
            return {
                code: 0,
                message: "No hay eventos para el calendario"
            };
        }
        
    } catch (error) {
        return {
            code    : -1,
            message : "No se pudo obtener los eventos"
        }
    }
}