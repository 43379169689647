<template>
    <div class="dashboard flex-row-center start-row-center" id="dashboard">

        <div class="wdt-x1 content-yellow full-height box-shadow">
            <SideBar 
                @changeView="(index_active) => changeView(index_active)"
            ></SideBar>
        </div>
        <div class="wdt-x9 full-height">
            <Summary v-if="active === 0"></Summary>
            <Projects v-if="active === 1"></Projects>
        </div>
    </div>
</template>

<script>
import Projects from '@/components/Projects.vue';
import Summary from '@/components/Summary.vue';
import SideBar from '@/components/SideBar.vue';

import { mapGetters, mapActions } from 'vuex';
import { auth } from "@/config/firebase/auth";

    export default {
        name: "Dashboard",
        data: () => {
            return {
                active: 0
            };
        },

        components: {
            Projects,
            Summary,
            SideBar
        },

        beforeMount () {
            if(!this.user.status || this.user.status === undefined) {
                this.$router.replace("/login");
            } else {
                this.initialData();
            }
        },  

        computed: {
            ...mapGetters('users', {
                user: "data_user"
            })
        },

        methods: {
            
            ...mapActions('users', {
                signout_store       : 'signOut'
            }),

            ...mapActions('projects', {
                get_projects_store  : 'getProjects' 
            }),


            async initialData() {
                let response = await this.get_projects_store(this.user.id);

                console.log(response);
            },

            async changeView(index_active) {
                if(index_active === 2) {
                    auth.signOut().then(() => {
                        this.signout_store();
                        this.$router.replace("/login");
                    });

                } else {
                    this.active = index_active;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/css/App.scss';

    #dashboard {
        height: 100vh;
        overflow: scroll;

    }

</style>
