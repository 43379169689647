<template>
    <div class="flex-column-center start-column-center wdt-x6 form-contact">

        <FormulateForm 
            class="flex-column-center center-column-start full-width column-form"
            @submit="sendMessage"
        >
            <FormulateInput 
                type="text"
                label="Nombres completos *"
                placeholder="Nombres y apellidos completos"
                name="Nombres y apellidos"
                validation="required"
                v-model="form.names"
            />

            <FormulateInput 
                type="email"
                label="Correo electrónico *"
                placeholder="ej. tucorreo@ejemplo.com"
                name="Correo electrónico"
                validation="required|email"
                v-model="form.email"
            />

            <FormulateInput 
                type="text"
                name="Asunto"
                label="Asunto del mensaje *"
                placeholder="¿Por qué motivo nos escríbes?"
                help="Puedes realizar cualquier tipo de consulta. Sé breve y específico con el asunto del mensaje"
                validation="required"
                v-model="form.subject"
            />

            <FormulateInput 
                type="textarea"
                name="Mensaje"
                label="Mensaje *"
                placeholder="Escribe tu mensaje aquí"
                validation="required"
                v-model="form.message"
            />


            <Loader :active="loader.active" :message="loader.text"></Loader>
            <Messages :active="messages.active" :message="messages.text" :type="messages.type"></Messages>


            <FormulateInput
                type="submit"
                label="Enviar mensaje"
                :disabled="loader.active"
            />

        </FormulateForm>



    </div>
</template>


<script>
import Loader from "@/utils/Loader.vue";
import Messages from "@/utils/Messages.vue";

export default {
    data:() =>{
        return {
             loader: {
                active: false,
                text: "Enviando..."
            },
            messages: {
                active: false,
                text: "",
                type: 0
            },

            form: {
                names: null,
                email: null,
                subject: null,
                message: null
            }
        };
    },

    components: {
        Loader,
        Messages
    },

    methods: {
        async sendMessage() {
            this.loader.active = true;

            let response = await this.axios.post("http://localhost:9000/projects-fair-rotary/us-central1/sendMail", this.form);

            console.log(response);

            if(response.data.code === 0) {
                this.setMessage(true, response.data.message, response.data.code);
            } else {
                this.setMessage(true, response.data.message + response.data.status, response.data.code);
            }
            this.loader.active = false;
        },

        setMessage(isActive, message, type) {
            this.messages.active = isActive;
            this.messages.text = message;
            this.messages.type = type;
        }
    },
}
</script>


<style lang="scss" scoped>
    @import '@/assets/css/App.scss';

    .form-contact {

        @include media("<phone") {
            width: 100% !important;
        }
    }

    .formulate-input {
        & [data-classification="text"] {
            .formulate-input-wrapper {
                width: 100% !important;
            }
        }
    }



</style>