<template>
    <div 
        :class="active ? 'content-share flex-column-center center-column-center full-size active': 'content-share flex-column-center center-column-center full-size'"
    >
        
        <div class="wdt-x5 content-white content-info-shared shadow">

            <div 
                class="hgh-x2 wdt-x10 flex-row-center end-row-center title-shared"
                :class="'background-focus-' + code"
            >
                <span class="text-subtitle-section text-white text-medium wdt-x10 no-margin">
                    {{title}}
                </span>

                <font-awesome-icon 
                    :icon="['fas', 'times-circle']" 
                    class="icon icon-medium icon-white"
                    @click="closeModal"
                />
            </div>

            <div class="hgh-x8 flex-column-center start-column-end">

                <span class="text-light mg-top-x1 wdt-x10">
                    {{ content}}
                </span>
                <span class="text-light mg-top-x1 wdt-x10">
                    {{ `Ver proyecto: ${link}` }}
                </span>

                <button 
                    class="text-white text-medium mg-top-x1"
                    :class="'background-focus-' + code "
                    @click="copyData"
                >
                    Copiar post
                </button>

                <span
                    class="text-medium fade-in-left "
                    :class="'text-focus-' + code"
                    v-if="copied"
                >   
                    Copiado en portapapeles
                </span>

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data: () => {
            return {
                title: "Compartir proyecto",
                content: "Servimos al mundo con ideas transformadoras que son llevadas a la acción para así cambiar la vida de nuestra comunidad. Te invitamos a revisar nuestra propuesta para la XVIII Feria de Proyectos Online del Distrito 4400 de Rotary Ecuador. \n \n",
                copied: false,
                copiedText: "Texto copiado",
                link: "https://projectsfair-rotaryecuador.org"
            };
        },
        props: {
            active: { 
                type: Boolean,
                default: false
            },
            code: {
                type: String,
                default: ""
            }
        },
        beforeMount () {
            this.link += this.$route.fullPath;
        },

        methods: {
            closeModal() {
                this.$emit('changeStateModal');
            },

            copyData() {
                if (
                    document.queryCommandSupported &&
                    document.queryCommandSupported("copy")
                ) {
                    var textarea = document.createElement("textarea");
                    textarea.textContent = this.content + this.link;
                    textarea.style.position = "fixed";
                    document.body.appendChild(textarea);
                    textarea.select();
                    try {
                        this.copied = true;
                        setTimeout(() => {
                            this.copied = false;
                        }, 1500);
                        return document.execCommand("copy");
                    } catch (ex) {
                        return false;
                    } finally {
                        document.body.removeChild(textarea);
                    }
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/css/App.scss';

    .content-share {
        opacity: 0;
        visibility: hidden;
        position: fixed;
        background: rgba(0, 0, 0, 0.5);
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 99999;

        overflow: scroll;

        .content-info-shared {
            padding: 2vw;
            border-radius: .6vw;
            justify-content: space-between;

            .title-shared {
                border-radius: .4vw;
                padding: 1vw 1vw;
                color: #fff;

            }
        }
    }

    .content-share.active {
        opacity: 1;
        visibility: visible;
    }
</style>