import * as getters from './getters';
import * as actions from './actions';
import mutations from './mutations';

const state = {
    status  : false,
    id      : null,
    data    : null
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}