<template>
    <div class="navbar-mobile">
        <div class="content-white cont-menu-mobile">
            <span
                v-for="(option, index) in menu"
                :key="'option_' + index"
                :class="active === index ? 'active' : ''"
                class="text-subtitle-section text-medium text-primary menu_option_mobile flex-column-center center-column-start"
                @click="goToSection(index)"
            >
                {{ option }}
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        data: () => {
            return {
                menu: [
                    "Inicio",
                    "Bienvenida",
                    "Calendario",
                    "Proyectos",
                    "Contacto"
                ],

                active: 0
            };
        },
        methods: {
            showNavBarMobile() {
               this.$emit('showNavBarMobile');
            },

            goToSection(index) {
                this.active = index;
                this.$emit('goToSection', index);
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/css/App.scss';

    .navbar-mobile {
        background: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 100vh;
        position: absolute;
        left: 0;
        top: 14vw;

        @include media(">=768px", "<=1024px") {
            top: 7vw;
        }

        .cont-menu-mobile {
            width: 50%;
            padding: 2vw 0vw;
            
            .menu_option_mobile {
                padding: 1em 2em;
                margin-bottom: 1vw;
                cursor: pointer;
            }

            .menu_option_mobile:active {
                background: #f7f7f7;
            }

            .menu_option_mobile.active {
                border-right: solid .2em #d91b5c;
                color: #d91b5c;
            }
            
        }
    }
</style>