<template>
    <div class="home">

        <Navbar
            @goToSection="(index) => goToSection(index)"
            :active="section_active"
        ></Navbar>

        <Portada    id="section_0"  ></Portada>
        <Laingments id="section_1"  ></Laingments>
        <Invitation id="section_1.1"  ></Invitation>
        <Focuses    id="section_2"  ></Focuses>
        <InvitationEN id="section_2.1"  ></InvitationEN>
        <Contact    id="section_3"  ></Contact>

        <Copyright></Copyright>

    </div>
</template>

<script>

    import Navbar       from "@/components/Navbar.vue";
    import Portada      from "@/components/landing/Portada.vue";
    import Laingments   from "@/components/landing/Laingments.vue";
    import Invitation   from "@/components/landing/Invitation.vue";
    import InvitationEN   from "@/components/landing/InvitationEN.vue";
    import Focuses      from "@/components/landing/Focuses.vue";
    import Contact      from "@/components/landing/Contact.vue";
    import Copyright    from "@/components/landing/Copyright.vue";

    import { scroller } from "vue-scrollto/src/scrollTo";

    export default {
        name: "Home",

        data:() => {
            return {
                section_active: 0,
                section: null
            }
        },

        components: {
            Navbar,
            Portada,
            Laingments,
            Invitation,
            InvitationEN,
            Focuses,
            Contact,
            Copyright
        },

        beforeMount() {
            
        },

        created () {

            window.document.body.onscroll = () => {

                var posScroll   = window.scrollY;
            
                var portrait    = document.getElementById('section_0').getBoundingClientRect().top + posScroll;
                var laignments  = document.getElementById('section_1').getBoundingClientRect().top + posScroll;
                var calendar    = document.getElementById('section_2').getBoundingClientRect().top + posScroll;
                var projects    = document.getElementById('section_3').getBoundingClientRect().top + posScroll;
                var contact     = document.getElementById('section_4').getBoundingClientRect().top + posScroll;

                if(posScroll > portrait           && posScroll < laignments)  this.section_active = 0;
                if(posScroll > laignments   - 50  && posScroll < calendar)    this.section_active = 1;
                if(posScroll > projects     - 50  && posScroll < contact)     this.section_active = 2;
                if(posScroll > contact      - 50)                             this.section_active = 3;
            }
        },

        methods: {
            goToSection(index) {
                let scrool = scroller();
                scrool("#section_" + index);
                this.section_active = index;
            }
        }
    }
</script>


<style lang="scss" scoped>
    @import '@/assets/css/App.scss';
</style>
