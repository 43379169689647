<template>
    <div class="contact wdt-x5 pd-left-x05 pd-top-x05 pd-bottom-x05 rsp-complete">

        <span class="text-medium text-subtitle-section mg-bottom-x2">
            Información de personal de contacto
        </span>

        <div class="wdt-x10 flex-row-center start-row-start mg-bottom-x05 change-to-column">
            <div class="wdt-x5 rsp-complete pd-right-x2 info-left">                
                <div class="mg-bottom-x05">
                    <span class="wdt-x10 text-medium text-grey text-subtitle-section">
                        Nombres completos
                    </span>
                    <span class="wdt-x10 text-light text-subtitle-section">
                        {{ contact.names }}
                    </span>
                </div>

                <div class="mg-bottom-x05">
                    <span class="wdt-x10 text-medium text-grey text-subtitle-section">
                        Correo electrónico
                    </span>
                    <span class="wdt-x10 text-light text-subtitle-section">
                        {{ contact.email }}
                    </span>
                </div>
            </div>

            <div class="wdt-x5 rsp-complete pd-left-x2 info-right">
                <div class="mg-bottom-x05">
                    <span class="wdt-x10 text-medium text-grey text-subtitle-section">
                        Apellidos completos
                    </span>
                    <span class="wdt-x10 text-light text-subtitle-section">
                        {{ contact.surnames }}
                    </span>
                </div>
                <div class="mg-bottom-x05">
                    <span class="wdt-x10 text-medium text-grey text-subtitle-section">
                        Número de teléfono
                    </span>

                    <div class="wdt-x10 flex-row-center start-row-center">
                        <span class="text-light text-subtitle-section no-margin"
                        >
                            {{ `+${contact.phone}` }}
                        </span>

                        <div class="wdt-x05"></div>

                        <span
                            class="text-green text-medium text-subtitle-section no-margin mg-left-x1"
                            style="cursor: pointer"
                            @click="whatMe(contact.phone)"
                        >
                            Enviar mensaje
                        </span>

                    </div>


                </div>
            </div>
        </div>

        <div class="wdt-x10 ">
            <span class="wdt-x10 text-medium text-grey text-subtitle-section">
                Cargos y actividades en el proyecto
            </span>
            <span class="wdt-x10 text-light text-subtitle-section">
                {{ contact.activities }}
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        data: () => {
            return {
                prefixMessage: "/?text=",
                message: "Hola, me interesa conocer más sobre el proyecto"
            };
        },

        props: {
            contact: {
                type: Object,
                default: () => {
                    return {};
                }
            }
        },

        methods: { 
            whatMe(phone) {
                let url = "https://wa.me/" + phone + this.prefixMessage + this.message;
                window.open(url, "_blank");
            }
        }

    }
</script>

<style lang="scss" scoped>
    @import "@/assets/css/App.scss";
    .contact {



        .info-right {
            @include media("<phone") {
                padding: 0 !important;
            }
    
            @include media(">phone", "<tablet") {
                padding: 0 !important;
            }

            @include media(">=768px", "<=1024px") {
                padding: 0 !important;
            }
        }
    }
</style>