<template>
    <div class="view-projects flex-column-center start-column-center" id="begin">

        <Navbar
            @goToSection="(index) => goToSection(index)"
            :active="3"
        ></Navbar>

        <Header 
            :focus="focuses[active]" 
            :total="listProjectsByCode.length"
        ></Header>

        <div class="wdt-x10 flex-row-center center-row-start full-size projects-section">
            <div class="cont-list-projects wdt-x7 flex-row-center start-row-start">
                
                <Loader :active="loader.active" :message="loader.text"></Loader>
                <Messages :active="messages.active" :message="messages.text" :type="messages.type"></Messages>

                <CardProject
                    v-for="(project, index) in filterProjectsByFocus"
                    :key="project.id"
                    :project="project"
                    style="height: 24vw"
                    :class="{
                        'first': index === 0 || (index % 3) === 0,
                        'last': index === filterProjectsByFocus.length - 1 
                    }"
                ></CardProject>
            </div>

            <div class="cont-filters wdt-x3  full-height">
                <Filters></Filters>
            </div>
        </div>
        
        <Copyright></Copyright>

    </div>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
    import { list_focuses_area } from "@/assets/resources/formats_data.js";
    import { scroller } from "vue-scrollto/src/scrollTo";

    import Loader from "@/utils/Loader.vue";
    import Messages from "@/utils/Messages.vue";

    import Header from "@/utils/Header.vue";
    import CardProject from "@/utils/CardProject.vue";
    import Filters from "@/utils/Filters.vue";

    import Copyright from "@/components/landing/Copyright.vue";
    import Navbar from "@/components/Navbar.vue";

    export default {
        name: "ViewProjects",
        data: () => {
            return {
                title: "Listado de proyectos",

                code: "",
                active: 0,

                loader: {
                    active: true,
                    text: ""
                },

                messages: {
                    active: false,
                    text: "",
                    type: 0
                },

                focuses: [],
            };
        },

        beforeMount () {
            this.initData();
            this.loadDataByCode();
        },

        components:{
            Navbar,
            Loader,
            Messages,
            Header,
            CardProject,
            Filters,
            Copyright
        },

        computed: {
            ...mapGetters('projects', {
                listProjectsByCode: "getListProjectsByCode",
                filterProjectsByFocus: "filterProjectsByFocus"
            })
        },

        methods: {

            ...mapActions('projects', {
                getProjectByCode: "getProjectByCode"
            }),

            async initData() {
                
                this.code                = this.$route.params.focus_code;
                
                this.loader.active      = true;
                this.messages.active    = false;
                this.loader.text        = "Obteniendo proyectos..."


                let response = await this.getProjectByCode(this.code);
                let scrool = scroller();
                
                scrool("#begin");

                if(response.code !== 0) {
                    this.setMessage(true, response.message, response.code);
                }

                this.loader.active      = false;

            },

            setMessage(isActive, message, type) {
                this.messages.active    = isActive;
                this.messages.text      = message;
                this.messages.type      = type;
            },

            loadDataByCode() {
                let list = list_focuses_area();
                list.forEach((item, index) => {
                    this.focuses.push(item);
                    if (item.code === this.code){
                        this.active = index;
                    }
                });
            },

            goToSection(index) {
                this.$router.push({
                    name: "Home"
                })
            }
        }
    }
    
</script>


<style lang="scss" scoped>
    @import '@/assets/css/App.scss';

    .view-projects {
        position: relative;

        .projects-section {
            padding: 2vw 4vw;

            @include media("<phone") {

                display: flex !important;
                flex-flow: column !important;
                align-items: start !important;
                justify-content: start !important;
            }

            @include media(">phone", "<tablet") {
                display: flex !important;
                flex-flow: column !important;
                align-items: start !important;
                justify-content: start !important;
            }

            @include media(">=768px", "<=1024px") {
                
            }

            @include media(">tablet", "<desktop") {
            }

            .cont-list-projects {
                flex-flow: row wrap;
                overflow: scroll;
                min-height: 54vw;
                height: 54vw;
                max-height: 80vw;

                @include media("<phone") {
                    width: 100% !important;
                    padding: 4vw 0;
                    min-height: 75vw;
                    height: 75vw;
                    max-height: 170vw;
                }

                @include media(">phone", "<tablet") {
                    width: 100% !important;
                    padding: 4vw 0;
                    min-height: 75vw;
                    height: 75vw;
                    max-height: 170vw;
                }
            }
            
            .cont-filters {
                padding: 0 2vw;
                
                @include media("<phone") {
                    width: 100% !important;
                }

                @include media(">phone", "<tablet") {
                    width: 100% !important;
                }
            }
        }


    }
</style>
