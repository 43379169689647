<template>
    <div 
        class="flex-row-center start-row-center full-width messages"
        :class="{
            'show': show,
            'hide': !show,
            'content-success': type === 0,
            'content-error': type === -1,
            'content-info': type === 1
        }"
    >
        <span class="text-medium text-white no-margin wdt-x9">{{ message }}</span>

        <font-awesome-icon 
            :icon="['fas', 'times-circle']" 
            class="icon icon-white wdt-x1 icon-medium" 
            @click="show = false;"
        />


    </div>
</template>

<script>

export default {

    data: () => {
        return {
            show: false
        };
    },
    props: {
        active: {
            type: Boolean,
            default: false
        },
        message: {
            type: String,
            default: ""
        },
        type: {
            type: Number,
            default: 0
        }
    },

    watch: {
        active: function(active) {
            this.show = active;
        }
    }
};
    
</script>

<style lang="scss" scoped>
    @import '@/assets/css/App.scss';
    
    .messages {
        padding: .75em 1em;
        border-radius: .6em;
        margin: .6vw 0;
        justify-content: space-between;
    }
</style>
