
<template>
    <div class="item-table flex-row-center start-row-center content-grey-d full-width" id="itemTable">

        <div class="wdt-x9 flex-row-center start-row-center">

            <div class="flex-column-center center-column-start wdt-x4 pd-right-x1">
                <span 
                    class="text-white focus-text text-medium"
                    :class="'background-focus-' + project.data.focus_area.code"
                >
                    {{ project.data.focus_area.name }}
                </span>
                <span class="full-width text-medium title-text">
                    {{ project.data.title }}
                </span>

                <span class="full-width text-light description-text">
                    {{ project.data.description }}
                </span>
            </div>


            <div class="wdt-x6 pd-left-x1 flex-row-center start-row-start">

                <div class="wdt-x5 flex-column-center center-column-center">
                    <span class="text-medium">Nombre del club</span>
                    <span class="text-light info-text">{{ project.data.club.name }}</span>
                    <span class="text-light info-text">{{ project.data.club.city }}</span>
                </div>

                <div class="wdt-x5 flex-column-center center-column-center">
                    <span class="text-medium">Contacto</span>
                    <span class="text-light info-text">
                        {{ project.data.contact.names + " " + project.data.contact.surnames }}
                    </span>
                    <span class="text-light info-text">{{ project.data.contact.phone }}</span>
                </div>

                <!-- <div class="wdt-x3 flex-column-center center-column-center">
                    <span class="text-medium">Completado</span>
                    <span class="text-light info-text">80%</span>
                </div> -->


            </div>
            
        </div>

        <div class="actions wdt-x1 flex-row-center center-row-center ">
            <button 
                class="content-info mg-right-x05"
                @click="selectAction(1, project)"
            >
                <font-awesome-icon 
                    :icon="['fas', 'edit']" 
                    class="icon icon-white" 
                />
            </button>

            <button 
                class="content-error mg-left-x05"
                @click="selectAction(2, project)"
            >
                <font-awesome-icon 
                    :icon="['fas', 'trash-alt']" 
                    class="icon icon-white" 
                />
            </button>
        </div>

    </div>
</template>


<style lang="scss" scoped>
    @import '@/assets/css/App.scss';

    .item-table {
        justify-content: space-between;
        padding: 1em;
        border-radius: .6em;
        margin-bottom: .8em;

        .focus-text {
            padding: .3em .8em;
            margin-bottom: .8em;
            border-radius: .4em;
            text-transform: uppercase;
        }

        .title-text, .description-text, .info-text {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    
</style>

<script>
export default {
    name: 'ItemTable',

    components: {  },

    directives: {  },

    data() {
        return {};
    },

    props: {
		project: {
			type: Object,
			default: () => {}
		}

    },
    mounted() {
        
    },

    methods: {
        selectAction(action, project) {
            this.$emit('selectActionToForm',  action, project);
        },
    },
};
</script>